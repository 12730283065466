import { apiFactory } from "@/api/factories/apiFactory";
class recommendedReportService {
    _recommendedReportApi = apiFactory.useRecommendedReportApi();
    async getRecommendedReports() {
        const recommendedReports = await this._recommendedReportApi.getRecommendedReports();
        if (recommendedReports.response && recommendedReports.success) {
            return recommendedReports.response;
        }
        return [];
    }
}
export default recommendedReportService;
