import { httpClient } from "@/helpers/httpClient";
import apiResponse from "@/models/base/apiResponse";
export default class workspaceApi {
    resource = "/api/powerbi/workspace";
    async getWorkspaces() {
        const response = new apiResponse(async () => await httpClient.get(this.resource));
        await response.ready;
        return response;
    }
}
