import productService from "@/services/application/productService";
import Dropdown from "@/components/root/dropdown.vue";
const productSvc = new productService();
export default {
    name: "ProductFilter",
    components: {
        Dropdown
    },
    props: {
        reportId: String,
        parentdepartment: String,
        department: String,
        group: String,
        subgroup: String,
        variantplu: String
    },
    emits: {
        productFilterReset: null,
        product: null
    },
    data() {
        return {
            showProductBreadcrumb: false,
            product: {
                hierarchyLevel: "AllProducts", currentProductLabel: "All Products",
                ParentDepartments: [], filteredParentDepartments: [], defaultParentDepartment: "All Chambers", currentParentDepartment: "All Chambers",
                Departments: [], filteredDepartments: [], defaultDepartment: "All Product Departments", currentDepartment: "All Product Departments",
                Groups: [], filteredGroups: [], defaultGroup: "All Product Groups", currentGroup: "All Product Groups",
                SubGroups: [], filteredSubGroups: [], defaultSubGroup: "All Product Sub Groups", currentSubGroup: "All Product Sub Groups",
                VariantPLUs: [], filteredVariantPLUs: [], defaultVariantPLU: "All Product Variant PLUs", currentVariantPLU: "All Product Variant PLUs"
            },
        };
    },
    computed: {
        productParentDepartmentIsActive() {
            switch (this.product.hierarchyLevel) {
                case "AllProducts":
                    return "Inactive";
                case "ProductLevel1":
                    return "Active";
                default:
                    return "None";
            }
        },
        productDepartmentIsActive() {
            switch (this.product.hierarchyLevel) {
                case "AllProducts":
                case "ProductLevel1":
                    return "Inactive";
                case "ProductLevel2":
                    return "Active";
                default:
                    return "None";
            }
        },
        productGroupIsActive() {
            switch (this.product.hierarchyLevel) {
                case "AllProducts":
                case "ProductLevel1":
                case "ProductLevel2":
                    return "Inactive";
                case "ProductLevel3":
                    return "Active";
                default:
                    return "None";
            }
        },
        productSubGroupIsActive() {
            switch (this.product.hierarchyLevel) {
                case "AllProducts":
                case "ProductLevel1":
                case "ProductLevel2":
                case "ProductLevel3":
                    return "Inactive";
                case "ProductLevel4":
                    return "Active";
                default:
                    return "None";
            }
        },
        productVariantPLUIsActive() {
            switch (this.product.hierarchyLevel) {
                case "ProductLevel6":
                    return "Active";
                default:
                    return "Inactive";
            }
        },
        productParentDepartmentsCount() {
            if (typeof (this.product.filteredParentDepartments) == "undefined")
                return 500;
            return this.product.filteredParentDepartments.length;
        },
        productDepartmentsCount() {
            if (typeof (this.product.filteredDepartments) == "undefined")
                return 500;
            return this.product.filteredDepartments.length;
        },
        productGroupsCount() {
            if (typeof (this.product.filteredGroups) == "undefined")
                return 500;
            return this.product.filteredGroups.length;
        },
        productSubGroupsCount() {
            if (typeof (this.product.filteredSubGroups) == "undefined")
                return 500;
            return this.product.filteredSubGroups.length;
        },
        productVariantPLUsCount() {
            if (typeof (this.product.filteredVariantPLUs) == "undefined")
                return 500;
            if (this.product.filteredVariantPLUs.length > 500)
                return 500;
            return this.product.filteredVariantPLUs.length;
        },
        productParentDepartment: {
            get() {
                return this.product.currentParentDepartment;
            },
            async set(val) {
                if (val == "All Chambers") {
                    await this.updateHierarchy("AllProducts", "All Products");
                }
                else {
                    await this.updateHierarchy("ProductLevel1", val);
                }
            }
        },
        productDepartment: {
            get() {
                return this.product.currentDepartment;
            },
            async set(val) {
                await this.updateHierarchy("ProductLevel2", val);
            }
        },
        productGroup: {
            get() {
                return this.product.currentGroup;
            },
            async set(val) {
                await this.updateHierarchy("ProductLevel3", val);
            }
        },
        productSubGroup: {
            get() {
                return this.product.currentSubGroup;
            },
            async set(val) {
                await this.updateHierarchy("ProductLevel4", val);
            }
        },
        productVariantPLU: {
            get() {
                return this.product.currentVariantPLU;
            },
            async set(val) {
                await this.updateHierarchy("ProductLevel6", val);
            }
        }
    },
    watch: {
        parentdepartment(newValue, oldValue) {
            if (newValue == undefined || newValue == oldValue)
                return;
            var selection = this.product.ParentDepartments.find(t => t.name == newValue);
            this.getProductParentDepartmentSelection(selection);
        },
        department(newValue, oldValue) {
            if (newValue == undefined || newValue == oldValue)
                return;
            var selection = this.product.Departments.find(t => t.name == newValue);
            this.getProductDepartmentSelection(selection);
        },
        group(newValue, oldValue) {
            if (newValue == undefined || newValue == oldValue)
                return;
            var selection = this.product.Groups.find(t => t.name == newValue);
            this.getProductGroupSelection(selection);
        },
        subgroup(newValue, oldValue) {
            if (newValue == undefined || newValue == oldValue)
                return;
            var selection = this.product.SubGroups.find(t => t.name == newValue);
            this.getProductSubGroupSelection(selection);
        },
        variantplu(newValue, oldValue) {
            if (newValue == undefined || newValue == oldValue)
                return;
            var selection = this.product.VariantPLUs.find(t => t.name == newValue);
            this.getProductVariantPLUSelection(selection);
        }
    },
    async mounted() {
        this.getProductDropdownData();
        window.addEventListener("click", this.handleBodyClick);
        window.addEventListener("blur", this.handleBodyClick);
    },
    beforeUnmount() {
        window.removeEventListener("click", this.handleBodyClick);
        window.removeEventListener("blur", this.handleBodyClick);
    },
    methods: {
        handleBodyClick(event) {
            try {
                let clickOutsideComponent = !this.$el.contains(event.target);
                if (clickOutsideComponent) {
                    this.showProductBreadcrumb = false;
                }
            }
            catch {
                this.showProductBreadcrumb = false;
            }
        },
        async getProductDropdownData() {
            this.getProductParentDepartments();
            this.getProductDepartments();
            this.getProductGroups();
            this.getProductSubGroups();
            this.getProductVariants();
        },
        async getProductParentDepartments() {
            const result = await productSvc.getProductParentDepartments();
            this.product.ParentDepartments = result;
            this.product.filteredParentDepartments = result;
        },
        async getProductDepartments() {
            const result = await productSvc.getProductDepartments();
            this.product.Departments = result;
            this.product.filteredDepartments = result;
        },
        async getProductGroups() {
            const result = await productSvc.getProductGroups();
            this.product.Groups = result;
            this.product.filteredGroups = result;
        },
        async getProductSubGroups() {
            const result = await productSvc.getProductSubGroups();
            this.product.SubGroups = result;
            this.product.filteredSubGroups = result;
        },
        async getProductVariants() {
            const result = await productSvc.getProductVariants();
            this.product.VariantPLUs = result;
            this.product.filteredVariantPLUs = result;
        },
        toggleProductSelection() {
            this.showProductBreadcrumb = !this.showProductBreadcrumb;
        },
        getProductParentDepartmentSelection(selection) {
            if (selection.name == this.product.currentParentDepartment)
                return;
            this.getFilterSelection(selection, "ProductLevel1");
        },
        getProductDepartmentSelection(selection) {
            if (selection.name == this.product.currentDepartment)
                return;
            this.getFilterSelection(selection, "ProductLevel2");
        },
        getProductGroupSelection(selection) {
            if (selection.name == this.product.currentGroup)
                return;
            this.getFilterSelection(selection, "ProductLevel3");
        },
        getProductSubGroupSelection(selection) {
            if (selection.name == this.product.currentSubGroup)
                return;
            this.getFilterSelection(selection, "ProductLevel4");
        },
        getProductVariantPLUSelection(selection) {
            if (selection.name == this.product.currentVariantPLU)
                return;
            this.getFilterSelection(selection, "ProductLevel6");
        },
        async getFilterSelection(selection, selectionType) {
            this.showProductBreadcrumb = false;
            if (selection.id == "0") {
                if (selectionType == "ProductLevel1") {
                    await (this.productParentDepartment = "All Chambers");
                }
                if (selectionType == "ProductLevel2") {
                    await (this.productParentDepartment = this.product.currentParentDepartment);
                }
                if (selectionType == "ProductLevel3") {
                    await (this.productDepartment = this.product.currentDepartment);
                }
                if (selectionType == "ProductLevel4") {
                    await (this.productGroup = this.product.currentGroup);
                }
                if (selectionType == "ProductLevel6") {
                    await (this.productSubGroup = this.product.currentSubGroup);
                }
                this.$emit("productFilterReset", selectionType);
            }
            if (selection.id != "0") {
                if (selectionType == "ProductLevel1") {
                    await (this.productParentDepartment = selection.name);
                }
                if (selectionType == "ProductLevel2") {
                    await (this.productDepartment = selection.name);
                }
                if (selectionType == "ProductLevel3") {
                    await (this.productGroup = selection.name);
                }
                if (selectionType == "ProductLevel4") {
                    await (this.productSubGroup = selection.name);
                }
                if (selectionType == "ProductLevel6") {
                    await (this.productVariantPLU = selection.name);
                }
            }
        },
        async updateHierarchy(level, value) {
            if (level == "AllProducts") {
                this.product.hierarchyLevel = "AllProducts";
                this.product.currentProductLabel = "All Products";
                this.product.currentParentDepartment = this.product.defaultParentDepartment;
                this.product.currentDepartment = this.product.defaultDepartment;
                this.product.currentGroup = this.product.defaultGroup;
                this.product.currentSubGroup = this.product.defaultSubGroup;
                this.product.currentVariantPLU = this.product.defaultVariantPLU;
                this.product.filteredParentDepartments = this.product.ParentDepartments;
                this.product.filteredDepartments = this.product.Departments;
                this.product.filteredGroups = this.product.Groups;
                this.product.filteredSubGroups = this.product.SubGroups;
                this.product.filteredVariantPLUs = this.product.VariantPLUs;
            }
            else if (level == "ProductLevel1") {
                this.product.hierarchyLevel = "ProductLevel1";
                this.product.currentProductLabel = value;
                this.product.currentParentDepartment = value;
                this.product.currentDepartment = this.product.defaultDepartment;
                this.product.currentGroup = this.product.defaultGroup;
                this.product.currentSubGroup = this.product.defaultSubGroup;
                this.product.currentVariantPLU = this.product.defaultVariantPLU;
                let parentDepartmentId = this.product.ParentDepartments.find((item) => item.name == value)?.id;
                this.product.filteredDepartments = this.product.Departments.filter(pro => pro.parentId == parentDepartmentId || pro.id == 0);
                this.product.filteredGroups = this.product.Groups.filter(pro => pro.parent2Id == parentDepartmentId || pro.id == 0);
                this.product.filteredSubGroups = this.product.SubGroups.filter(pro => pro.parent3Id == parentDepartmentId || pro.id == 0);
                this.product.filteredVariantPLUs = this.product.VariantPLUs.filter(pro => pro.parent5Id == parentDepartmentId || pro.id == 0);
            }
            else if (level == "ProductLevel2") {
                this.product.hierarchyLevel = "ProductLevel2";
                this.product.currentProductLabel = value;
                let parentDepartmentId = this.product.Departments.find((item) => item.name == value)?.parentId;
                let parentDepartmentName = this.product.ParentDepartments.find((item) => item.id == parentDepartmentId)?.name;
                this.product.currentParentDepartment = parentDepartmentName;
                this.product.currentDepartment = value;
                this.product.currentGroup = this.product.defaultGroup;
                this.product.currentSubGroup = this.product.defaultSubGroup;
                this.product.currentVariantPLU = this.product.defaultVariantPLU;
                let departmentId = this.product.Departments.find((item) => item.name == value)?.id;
                this.product.filteredGroups = this.product.Groups.filter(pro => pro.parentId == departmentId || pro.id == 0);
                this.product.filteredSubGroups = this.product.SubGroups.filter(pro => pro.parent2Id == departmentId || pro.id == 0);
                this.product.filteredVariantPLUs = this.product.VariantPLUs.filter(pro => pro.parent4Id == departmentId || pro.id == 0);
            }
            else if (level == "ProductLevel3") {
                this.product.hierarchyLevel = "ProductLevel3";
                this.product.currentProductLabel = value;
                let parentDepartmentId = this.product.Groups.find((item) => item.name == value)?.parent2Id;
                let parentDepartmentName = this.product.ParentDepartments.find((item) => item.id == parentDepartmentId)?.name;
                this.product.currentParentDepartment = parentDepartmentName;
                let departmentId = this.product.Groups.find((item) => item.name == value)?.parentId;
                let departmentName = this.product.Departments.find((item) => item.id == departmentId)?.name;
                this.product.currentDepartment = departmentName;
                this.product.currentGroup = value;
                this.product.currentSubGroup = this.product.defaultSubGroup;
                this.product.currentVariantPLU = this.product.defaultVariantPLU;
                let groupId = this.product.Groups.find((item) => item.name == value)?.id;
                this.product.filteredSubGroups = this.product.SubGroups.filter(pro => pro.parentId == groupId || pro.id == 0);
                this.product.filteredVariantPLUs = this.product.VariantPLUs.filter(pro => pro.parent3Id == groupId || pro.id == 0);
            }
            else if (level == "ProductLevel4") {
                this.product.hierarchyLevel = "ProductLevel4";
                this.product.currentProductLabel = value;
                let parentDepartmentId = this.product.SubGroups.find((item) => item.name == value)?.parent3Id;
                let parentDepartmentName = this.product.ParentDepartments.find((item) => item.id == parentDepartmentId)?.name;
                this.product.currentParentDepartment = parentDepartmentName;
                let departmentId = this.product.SubGroups.find((item) => item.name == value)?.parent2Id;
                let departmentName = this.product.Departments.find((item) => item.id == departmentId)?.name;
                this.product.currentDepartment = departmentName;
                let groupId = this.product.SubGroups.find((item) => item.name == value)?.parentId;
                let groupName = this.product.Groups.find((item) => item.id == groupId)?.name;
                this.product.currentGroup = groupName;
                this.product.currentSubGroup = value;
                this.product.currentVariantPLU = this.product.defaultVariantPLU;
                let subGroupId = this.product.SubGroups.find((item) => item.name == value)?.id;
                this.product.filteredVariantPLUs = this.product.VariantPLUs.filter(pro => pro.parent2Id == subGroupId || pro.id == 0);
            }
            else if (level == "ProductLevel6") {
                this.product.hierarchyLevel = "ProductLevel6";
                this.product.currentProductLabel = value;
                let parentDepartmentId = this.product.VariantPLUs.find((item) => item.name == value)?.parent5Id;
                let parentDepartmentName = this.product.ParentDepartments.find((item) => item.id == parentDepartmentId)?.name;
                this.product.currentParentDepartment = parentDepartmentName;
                let departmentId = this.product.VariantPLUs.find((item) => item.name == value)?.parent4Id;
                let departmentName = this.product.Departments.find((item) => item.id == departmentId)?.name;
                this.product.currentDepartment = departmentName;
                let groupId = this.product.VariantPLUs.find((item) => item.name == value)?.parent3Id;
                let groupName = this.product.Groups.find((item) => item.id == groupId)?.name;
                this.product.currentGroup = groupName;
                let subGroupId = this.product.VariantPLUs.find((item) => item.name == value)?.parent2Id;
                let subGroupName = this.product.SubGroups.find((item) => item.id == subGroupId)?.name;
                this.product.currentSubGroup = subGroupName;
                this.product.currentVariantPLU = value;
            }
            this.$emit("product", {
                hierarchyLevel: this.product.hierarchyLevel,
                parentDepartment: this.product.currentParentDepartment,
                department: this.product.currentDepartment,
                group: this.product.currentGroup,
                subGroup: this.product.currentSubGroup,
                variantPLU: this.product.currentVariantPLU
            });
        },
    }
};
