import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0f82f477"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "app-page overflow-auto" };
import { RouterView } from "vue-router";
import { ref } from "vue";
import AppBar from "@/components/root/AppBar.vue";
import BottomBar from "@/components/root/BottomBar.vue";
import HealthCheck from "@/components/root/HealthCheck.vue";
import SidebarNav from "@/components/root/SidebarNav.vue";
import Notification from "@/components/root/Notification.vue";
import Toaster from "@/components/root/ToastComponent.vue";
import authService from "@/services/application/authService";
export default _defineComponent({
    __name: 'App',
    setup(__props) {
        const authSvc = new authService();
        const sidebarNav = ref(null);
        function handleCollapseSidebar() {
            sidebarNav.value?.collapseSidebar();
        }
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("main", null, [
                _createVNode(SidebarNav, {
                    ref_key: "sidebarNav",
                    ref: sidebarNav,
                    onLogout: _unref(authSvc).logout
                }, null, 8, ["onLogout"]),
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(AppBar),
                    _createElementVNode("div", null, [
                        _createVNode(Notification),
                        _createVNode(_unref(RouterView), { onCollapseSidebar: handleCollapseSidebar }),
                        _createVNode(Toaster)
                    ]),
                    _createVNode(BottomBar, {
                        onLogout: _unref(authSvc).logout
                    }, null, 8, ["onLogout"])
                ]),
                _createVNode(HealthCheck)
            ]));
        };
    }
});
