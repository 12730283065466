import { apiFactory } from "@/api/factories/apiFactory";
class locationService {
    _locationApi = apiFactory.useLocationApi();
    async getTradingStatuses() {
        const tradingStatuses = await this._locationApi.getTradingStatuses();
        if (tradingStatuses.response && tradingStatuses.success) {
            return tradingStatuses.response;
        }
        return [];
    }
    async getRegions() {
        const regions = await this._locationApi.getRegions();
        if (regions.response && regions.success) {
            return regions.response;
        }
        return [];
    }
    async getAreas(regionId) {
        const areas = await this._locationApi.getAreas(regionId);
        if (areas.response && areas.success) {
            return areas.response;
        }
        return [];
    }
    async getStores(areaId) {
        const stores = await this._locationApi.getStores(areaId);
        if (stores.response && stores.success) {
            return stores.response;
        }
        return [];
    }
}
export default locationService;
