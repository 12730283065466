import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../../assets/images/ViewpointIcon.svg';
import _imports_1 from '../../assets/images/ViewpointLogo.svg';
const _withScopeId = n => (_pushScopeId("data-v-79cc742a"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["id"];
const _hoisted_2 = { class: "d-xs-block d-sm-none d-flex offcanvas-header py-0 text-light" };
const _hoisted_3 = _withScopeId(() => _createElementVNode("span", { class: "fs-4" }, "Viewpoint", -1));
const _hoisted_4 = ["data-bs-target"];
const _hoisted_5 = { class: "fs-6 p-2 ms-3" };
const _hoisted_6 = {
    src: _imports_0,
    class: "me-2 iconColour",
    height: "26",
    width: "26"
};
const _hoisted_7 = {
    src: _imports_1,
    class: "me-2 iconColour",
    height: "26",
    width: "200"
};
const _hoisted_8 = _withScopeId(() => _createElementVNode("hr", { class: "text-secondary" }, null, -1));
const _hoisted_9 = { class: "nav nav-pills flex-column mb-auto" };
const _hoisted_10 = ["data-bs-toggle", "aria-controls", "href"];
const _hoisted_11 = { class: "row row-cols-1" };
const _hoisted_12 = { class: "col mx-1 text-center" };
const _hoisted_13 = {
    width: "24",
    height: "24",
    fill: "currentColor",
    class: "me-2"
};
const _hoisted_14 = ["xlink:href"];
const _hoisted_15 = {
    class: "col mx-auto text-center",
    style: { "font-size": "12px" }
};
const _hoisted_16 = ["id"];
const _hoisted_17 = { class: "router-link-span d-inline-flex text-nowrap" };
const _hoisted_18 = {
    width: "24",
    height: "24",
    fill: "currentColor",
    class: "me-2 mb-1"
};
const _hoisted_19 = ["xlink:href"];
const _hoisted_20 = ["id"];
const _hoisted_21 = { class: "nav nav-pills flex-column mb-auto" };
const _hoisted_22 = { class: "row row-cols-1" };
const _hoisted_23 = { class: "col mx-1 text-center" };
const _hoisted_24 = {
    width: "24",
    height: "24",
    fill: "currentColor",
    class: "me-2"
};
const _hoisted_25 = ["xlink:href"];
const _hoisted_26 = { class: "col" };
const _hoisted_27 = { class: "col" };
const _hoisted_28 = { class: "d-none d-md-block" };
const _hoisted_29 = ["title"];
const _hoisted_30 = {
    width: 25,
    height: 25,
    fill: "currentColor"
};
const _hoisted_31 = ["xlink:href"];
const _hoisted_32 = { class: "m-1" };
const _hoisted_33 = _withScopeId(() => _createElementVNode("hr", { class: "m-0 text-secondary" }, null, -1));
import { sidebarId } from "./constants";
import ProfileButton from "./ProfileButton.vue";
import bootstrapIcons from "@/assets/images/bootstrapIcons";
import { useApplicationStore } from "@/stores/applicationStore";
import { ref, onMounted } from "vue";
import { Offcanvas } from "bootstrap";
export default _defineComponent({
    __name: 'SidebarNav',
    emits: ["logout"],
    setup(__props, { expose, emit }) {
        let isSidebarCollapsed = ref(false);
        let isOnMobile = ref(false);
        onMounted(() => {
            isOnMobile.value = window.innerWidth < 992;
            window.addEventListener("resize", onResize);
        });
        function toggleSidebarCollapse(isCollapse) {
            if (isCollapse) {
                collapseSidebar();
            }
            else {
                expandSidebar();
            }
        }
        function expandSidebar() {
            const sidebar = document.getElementById(sidebarId) ?? new HTMLElement();
            isSidebarCollapsed.value = false;
            sidebar.style.width = "280px";
        }
        function collapseSidebar() {
            const sidebar = document.getElementById(sidebarId) ?? new HTMLElement();
            if (!isOnMobile.value) {
                isSidebarCollapsed.value = true;
                sidebar.style.width = "90px";
            }
            else {
                const sidebarOffcanvas = Offcanvas.getInstance(sidebar);
                sidebarOffcanvas?.hide();
            }
        }
        function onResize() {
            if (window.innerWidth < 992) {
                isOnMobile.value = true;
                toggleSidebarCollapse(false);
            }
            else {
                isOnMobile.value = false;
            }
        }
        expose({ collapseSidebar });
        const applicationStore = useApplicationStore();
        return (_ctx, _cache) => {
            const _component_RouterLink = _resolveComponent("RouterLink");
            return (_unref(applicationStore).menuData != undefined)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    id: _unref(sidebarId),
                    class: "offcanvas-lg offcanvas-start d-flex flex-column flex-shrink-0 py-2 bg-primary overflow-auto shadow-sm"
                }, [
                    _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_2, [
                            _hoisted_3,
                            _createElementVNode("button", {
                                type: "button",
                                class: "btn-close text-light",
                                "data-bs-dismiss": "offcanvas",
                                "data-bs-target": `#${_unref(sidebarId)}`,
                                "aria-label": "Close"
                            }, "   ", 8, _hoisted_4)
                        ]),
                        _createVNode(_component_RouterLink, {
                            to: "/",
                            class: "mb-3 mt-2 d-none d-sm-block d-flex align-items-center me-auto link-light text-decoration-none"
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_5, [
                                    _withDirectives(_createElementVNode("img", _hoisted_6, null, 512), [
                                        [_vShow, _unref(isSidebarCollapsed)]
                                    ]),
                                    _withDirectives(_createElementVNode("img", _hoisted_7, null, 512), [
                                        [_vShow, !_unref(isSidebarCollapsed)]
                                    ])
                                ])
                            ]),
                            _: 1
                        }),
                        _hoisted_8
                    ]),
                    _createElementVNode("ul", _hoisted_9, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(applicationStore).menuData.menuItems, (menuItem) => {
                            return (_openBlock(), _createElementBlock("li", {
                                key: menuItem.routeName,
                                class: "nav-item align-items-center py-1"
                            }, [
                                _createElementVNode("div", {
                                    "data-bs-toggle": _unref(isSidebarCollapsed) ? '' : 'collapse',
                                    "aria-controls": `#${menuItem.routeName}-submenu`,
                                    href: `#${menuItem.menuName}-submenu`
                                }, [
                                    (menuItem.routeName)
                                        ? (_openBlock(), _createBlock(_component_RouterLink, {
                                            key: 0,
                                            id: menuItem.routeName,
                                            to: { name: menuItem.routeName, query: { firstPage: 'true' } },
                                            title: menuItem.label,
                                            class: "d-flex nav-link link-light align-items-center"
                                        }, {
                                            default: _withCtx(() => [
                                                _createElementVNode("div", {
                                                    class: "router-link-span d-inline-flex text-nowrap",
                                                    onClick: _cache[0] || (_cache[0] = ($event) => (toggleSidebarCollapse(true)))
                                                }, [
                                                    _createElementVNode("div", _hoisted_11, [
                                                        _createElementVNode("div", _hoisted_12, [
                                                            (_openBlock(), _createElementBlock("svg", _hoisted_13, [
                                                                _createElementVNode("use", {
                                                                    "xlink:href": `${_unref(bootstrapIcons)}#${menuItem.icon}`
                                                                }, null, 8, _hoisted_14)
                                                            ])),
                                                            _withDirectives(_createElementVNode("span", { class: "ms-3" }, _toDisplayString(menuItem.label), 513), [
                                                                [_vShow, !_unref(isSidebarCollapsed)]
                                                            ])
                                                        ]),
                                                        _createElementVNode("div", _hoisted_15, [
                                                            _withDirectives(_createElementVNode("span", { class: "" }, _toDisplayString(menuItem.label), 513), [
                                                                [_vShow, _unref(isSidebarCollapsed)]
                                                            ])
                                                        ])
                                                    ])
                                                ])
                                            ]),
                                            _: 2
                                        }, 1032, ["id", "to", "title"]))
                                        : (_openBlock(), _createElementBlock("div", {
                                            key: 1,
                                            id: menuItem.menuName,
                                            class: "d-flex nav-link link-light align-items-center mb-1",
                                            onClick: _cache[1] || (_cache[1] = ($event) => (toggleSidebarCollapse(false)))
                                        }, [
                                            _createElementVNode("div", _hoisted_17, [
                                                (_openBlock(), _createElementBlock("svg", _hoisted_18, [
                                                    _createElementVNode("use", {
                                                        "xlink:href": `${_unref(bootstrapIcons)}#${menuItem.icon}`
                                                    }, null, 8, _hoisted_19)
                                                ])),
                                                _withDirectives(_createElementVNode("span", { class: "ms-3" }, _toDisplayString(menuItem.label), 513), [
                                                    [_vShow, !_unref(isSidebarCollapsed)]
                                                ])
                                            ])
                                        ], 8, _hoisted_16))
                                ], 8, _hoisted_10),
                                _withDirectives(_createElementVNode("div", {
                                    id: `${menuItem.menuName}-submenu`,
                                    class: "collapse"
                                }, [
                                    _createElementVNode("ul", _hoisted_21, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem.children, (childMenuItem) => {
                                            return (_openBlock(), _createElementBlock("li", {
                                                key: childMenuItem.routeName,
                                                class: "nav-item align-items-center submenu mb-2 py-1"
                                            }, [
                                                _createVNode(_component_RouterLink, {
                                                    id: childMenuItem.routeName,
                                                    to: { name: childMenuItem.routeName },
                                                    title: childMenuItem.label,
                                                    class: "d-flex nav-link link-light align-items-center px-2 mx-0"
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createElementVNode("div", {
                                                            class: "router-link-span d-inline-flex text-nowrap",
                                                            onClick: _cache[2] || (_cache[2] = ($event) => (toggleSidebarCollapse(!_unref(isSidebarCollapsed))))
                                                        }, [
                                                            _createElementVNode("div", _hoisted_22, [
                                                                _createElementVNode("div", _hoisted_23, [
                                                                    (_openBlock(), _createElementBlock("svg", _hoisted_24, [
                                                                        _createElementVNode("use", {
                                                                            "xlink:href": `${_unref(bootstrapIcons)}#${childMenuItem.icon}`
                                                                        }, null, 8, _hoisted_25)
                                                                    ]))
                                                                ]),
                                                                _createElementVNode("div", _hoisted_26, [
                                                                    _withDirectives(_createElementVNode("span", { class: "ms-3" }, _toDisplayString(childMenuItem.label), 513), [
                                                                        [_vShow, !_unref(isSidebarCollapsed)]
                                                                    ])
                                                                ]),
                                                                _createElementVNode("div", _hoisted_27, [
                                                                    _withDirectives(_createElementVNode("span", { class: "ms-3" }, _toDisplayString(childMenuItem.label), 513), [
                                                                        [_vShow, _unref(isSidebarCollapsed)]
                                                                    ])
                                                                ])
                                                            ])
                                                        ])
                                                    ]),
                                                    _: 2
                                                }, 1032, ["id", "to", "title"])
                                            ]));
                                        }), 128))
                                    ])
                                ], 8, _hoisted_20), [
                                    [_vShow, !_unref(isSidebarCollapsed)]
                                ])
                            ]));
                        }), 128))
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                        (!_unref(isOnMobile))
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                id: "collapse_sidebar",
                                class: "d-inline-flex text-nowrap link-light px-3 py-3 align-items-center text-decoration-none router-link-span",
                                title: _unref(isSidebarCollapsed) ? 'Expand Sidebar' : 'Collapse Sidebar',
                                onClick: _cache[3] || (_cache[3] = ($event) => (toggleSidebarCollapse(!_unref(isSidebarCollapsed))))
                            }, [
                                (_openBlock(), _createElementBlock("svg", _hoisted_30, [
                                    _createElementVNode("use", {
                                        "xlink:href": _unref(isSidebarCollapsed) ? `${_unref(bootstrapIcons)}#chevron-double-right` : `${_unref(bootstrapIcons)}#chevron-double-left`
                                    }, null, 8, _hoisted_31)
                                ])),
                                _withDirectives(_createElementVNode("div", _hoisted_32, "Hide menu", 512), [
                                    [_vShow, !_unref(isSidebarCollapsed)]
                                ])
                            ], 8, _hoisted_29))
                            : _createCommentVNode("", true),
                        _hoisted_33,
                        _withDirectives(_createVNode(ProfileButton, {
                            "profile-details": _unref(applicationStore).menuData.profileDetails,
                            "avatar-only": _unref(isSidebarCollapsed),
                            onLogout: _cache[4] || (_cache[4] = ($event) => (emit('logout'))),
                            onClick: _cache[5] || (_cache[5] = ($event) => (expandSidebar()))
                        }, null, 8, ["profile-details", "avatar-only"]), [
                            [_vShow, _unref(applicationStore).menuData.profileDetails != undefined]
                        ])
                    ])
                ], 8, _hoisted_1))
                : _createCommentVNode("", true);
        };
    }
});
