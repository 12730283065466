import { httpClient } from "@/helpers/httpClient";
import apiResponse from "@/models/base/apiResponse";
export default class reportApi {
    resource = "/api/powerbi/workspace";
    async getReports(workspaceId) {
        const res = new apiResponse(async () => await httpClient.get(`${this.resource}/${workspaceId}/reports`));
        await res.ready;
        return res;
    }
}
