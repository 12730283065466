import apiResponse from "@/models/base/apiResponse";
import { httpClient } from "@/helpers/httpClient";
export default class calendarApi {
    resource = "/api/application/calendar";
    async getCalendar() {
        const response = new apiResponse(async () => await httpClient.get(`${this.resource}/calendar`));
        await response.ready;
        return response;
    }
}
