import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { class: "px-5 pt-2 mx-auto" };
const _hoisted_2 = { class: "input-group shadow-sm border rounded-2" };
const _hoisted_3 = { class: "input-group-prepend" };
const _hoisted_4 = { class: "input-group-text" };
const _hoisted_5 = {
    class: "bi",
    width: "24",
    height: "24",
    fill: "#A0202E"
};
const _hoisted_6 = ["xlink:href"];
import { ref } from "vue";
import bootstrapIcons from "@/assets/images/bootstrapIcons";
export default _defineComponent({
    __name: 'SearchBar',
    emits: ["search-string-updated"],
    setup(__props, { emit }) {
        const searchInput = ref("");
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("span", _hoisted_4, [
                            (_openBlock(), _createElementBlock("svg", _hoisted_5, [
                                _createElementVNode("use", {
                                    "xlink:href": `${_unref(bootstrapIcons)}#search`
                                }, null, 8, _hoisted_6)
                            ]))
                        ])
                    ]),
                    _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((searchInput).value = $event)),
                        type: "text",
                        placeholder: "Search for Reports...",
                        class: "form-control search",
                        "data-testid": "SearchBar",
                        onKeyup: _cache[1] || (_cache[1] = ($event) => (emit('search-string-updated', searchInput.value)))
                    }, null, 544), [
                        [_vModelText, searchInput.value]
                    ])
                ])
            ]));
        };
    }
});
