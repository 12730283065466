import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/bootstrapIcons';
const _hoisted_1 = _imports_0 + '#calendar';
const _hoisted_2 = { class: "filtermenu pe-3 mx-2 mb-2" };
const _hoisted_3 = { class: "input-group input-group-sm" };
const _hoisted_4 = _createElementVNode("div", {
    class: "input-group-prepend rounded-0",
    style: { "min-width": "36px" }
}, [
    _createElementVNode("div", { class: "input-group-text prependLabel p-1" }, [
        _createElementVNode("svg", {
            class: "ms-1 rounded-0",
            width: "18",
            height: "22",
            fill: "currentColor"
        }, [
            _createElementVNode("use", { "xlink:href": _hoisted_1 })
        ])
    ])
], -1);
const _hoisted_5 = ["value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
            _hoisted_4,
            _createElementVNode("input", {
                id: "date",
                ref: "datePicker",
                value: $options.setTodayDate(),
                class: "form-control",
                type: "date",
                required: "",
                onChange: _cache[0] || (_cache[0] =
                    (...args) => ($options.onChangeOfDate && $options.onChangeOfDate(...args)))
            }, null, 40, _hoisted_5)
        ])
    ]));
}
