import apiResponse from "@/models/base/apiResponse";
import { httpClient } from "@/helpers/httpClient";
export default class logFilterApi {
    resource = "/api/application/logfilter";
    async logFilter(filters, route) {
        const requestData = { Filters: filters, Route: route };
        const response = new apiResponse(async () => await httpClient.post(this.resource, requestData));
        await response.ready;
        return response;
    }
}
