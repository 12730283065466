import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import _imports_0 from '@/assets/images/bootstrapIcons';
const _hoisted_1 = _imports_0 + '#exclamation-triangle';
const _hoisted_2 = _imports_0 + '#x-lg';
const _hoisted_3 = { class: "row justify-content-center" };
const _hoisted_4 = {
    class: "alert alert-danger d-flex px-1 py-0 my-0",
    role: "alert"
};
const _hoisted_5 = _createElementVNode("svg", {
    class: "bi mx-1 my-auto",
    width: "20",
    height: "20",
    fill: "white"
}, [
    _createElementVNode("use", { "xlink:href": _hoisted_1 })
], -1);
const _hoisted_6 = { class: "mx-1 my-auto text-white" };
const _hoisted_7 = _createElementVNode("svg", {
    class: "bi",
    fill: "white",
    width: "20",
    height: "20"
}, [
    _createElementVNode("use", { "xlink:href": _hoisted_2 })
], -1);
const _hoisted_8 = [
    _hoisted_7
];
const _hoisted_9 = { class: "mx-2 my-1 small" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
                _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: $data.notification.id,
                    style: { "z-index": "100" },
                    class: "position-absolute col-9 col-lg-8 mt-5 p-0 bg-white border rounded shadow"
                }, [
                    _createElementVNode("div", _hoisted_4, [
                        _hoisted_5,
                        _createElementVNode("div", _hoisted_6, _toDisplayString($data.notification.title), 1),
                        _createElementVNode("div", {
                            class: "showPointer ms-auto mx-1 my-1",
                            title: "Dismiss Notification",
                            onClick: _cache[0] || (_cache[0] = ($event) => ($options.dismissNotification($data.notification)))
                        }, _hoisted_8)
                    ]),
                    _createElementVNode("div", _hoisted_9, _toDisplayString($data.notification.body), 1)
                ])), [
                    [_vShow, $data.notification.showNotification]
                ])
            ]),
            _: 1
        })
    ]));
}
