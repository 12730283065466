import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ReportContainer = _resolveComponent("ReportContainer");
    return (_openBlock(), _createBlock(_component_ReportContainer, {
        ref: "reportContainer",
        "embedded-token": $props.embeddedToken,
        "report-id": $props.reportId,
        "event-handlers": $data.eventHandler,
        theme: $props.theme,
        onRendered: $options.ReportRendered,
        onLoaded: $options.ReportLoaded,
        onBookmarkLoaded: $options.hasBookmarkLoaded
    }, null, 8, ["embedded-token", "report-id", "event-handlers", "theme", "onRendered", "onLoaded", "onBookmarkLoaded"]));
}
