import { apiFactory } from "@/api/factories/apiFactory";
class logFilterService {
    _logFilterApi = apiFactory.useLogFilterApi();
    async logFilter(filterApplied, route) {
        const filters = filterApplied.map((filter) => {
            const { conditions, logicalOperator, operator, target, values } = filter;
            return { conditions, logicalOperator, operator, target, values };
        });
        const cleanedFilters = this.cleanFilters(filters);
        const response = await this._logFilterApi.logFilter(cleanedFilters, route);
        return response.success;
    }
    cleanFilters(filters) {
        return JSON.parse(JSON.stringify(filters, (key, value) => value === undefined ||
            value === null ||
            (Array.isArray(value) && value.length === 0) ||
            (Array.isArray(value) && value[0] === "") ? undefined : value));
    }
}
export default logFilterService;
