import { apiFactory } from "@/api/factories/apiFactory";
class routeReportService {
    _routeReportApi = apiFactory.useRouteReportApi();
    async getRouteReports() {
        const routeReports = await this._routeReportApi.getRouteReports();
        if (routeReports.response && routeReports.success) {
            return routeReports.response;
        }
        return [];
    }
}
export default routeReportService;
