import notificationService from "@/services/application/notificationService";
const notificationSvc = new notificationService();
export default {
    name: "NotificationComponent",
    data() {
        return {
            notification: {},
            showNotification: true,
            hiddenNotificationId: 0
        };
    },
    async mounted() {
        await this.getNotification();
        this.checkNotification();
    },
    methods: {
        async getNotification() {
            const result = await notificationSvc.getNotification();
            if (result.id == this.hiddenNotificationId)
                return;
            result.expiryDate = new Date(result.expiryDate);
            if (result.expiryDate > new Date()) {
                result.showNotification = true;
            }
            this.notification = result;
        },
        async dismissNotification(notification) {
            if (this.notification.id != notification.id)
                return;
            else {
                this.notification.showNotification = false;
                this.hiddenNotificationId = this.notification.id;
            }
        },
        async expireNotification() {
            if (this.notification.expiryDate <= new Date()) {
                this.notification.showNotification = false;
            }
        },
        async checkNotification() {
            let runLoop = true;
            while (runLoop) {
                await this.getNotification();
                await this.expireNotification();
                await this.delay(60000);
            }
        },
        delay(ms) { return new Promise(resolve => setTimeout(resolve, ms)); },
    }
};
