import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1911d656"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    id: "staticBackdrop",
    ref: "noConnectionModal",
    class: "modal d-block",
    "data-bs-backdrop": "static",
    "data-bs-keyboard": "false",
    tabindex: "-1",
    "aria-labelledby": "staticBackdropLabel",
    "aria-hidden": "true"
};
const _hoisted_2 = _withScopeId(() => _createElementVNode("div", { class: "modal-dialog" }, [
    _createElementVNode("div", { class: "modal-content" }, [
        _createElementVNode("div", { class: "modal-header" }, [
            _createElementVNode("h5", {
                id: "staticBackdropLabel",
                class: "modal-title"
            }, " Lost Connection to Server ")
        ]),
        _createElementVNode("div", { class: "modal-body" }, " Lost connection to server. Please check your internet connection or refresh the page. ")
    ])
], -1));
const _hoisted_3 = [
    _hoisted_2
];
import { computed } from "@vue/reactivity";
import { ref } from "vue";
import healthCheckService from "@/services/application/healthCheckService";
export default _defineComponent({
    __name: 'HealthCheck',
    setup(__props) {
        const lostConnection = ref(false);
        const timeoutDelay = computed(() => lostConnection.value ? 10000 : 150000);
        const healthCheckSvc = new healthCheckService();
        checkConnection();
        function checkConnection() {
            healthCheckSvc.checkConnection()
                .then(result => {
                lostConnection.value = !result;
                setTimeout(() => checkConnection(), timeoutDelay.value);
            });
        }
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
                default: _withCtx(() => [
                    (lostConnection.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _hoisted_3, 512))
                        : _createCommentVNode("", true)
                ]),
                _: 1
            }));
        };
    }
});
