import { apiFactory } from "@/api/factories/apiFactory";
class reportPageService {
    _reportPageApi = apiFactory.useReportPageApi();
    async getReportPages() {
        const reportPages = await this._reportPageApi.getReportPages();
        if (reportPages.response && reportPages.success) {
            return reportPages.response;
        }
        return [];
    }
}
export default reportPageService;
