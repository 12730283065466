import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "filtermenu pe-3 mx-2 mb-2" };
const _hoisted_2 = { class: "filteritem" };
const _hoisted_3 = { class: "weekFilterItem filtermenu pe-3 mx-2 mb-2" };
const _hoisted_4 = { class: "filteritem" };
const _hoisted_5 = { class: "weekFilterItem filtermenu pe-3 mx-2 mb-2" };
const _hoisted_6 = { class: "filteritem" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Dropdown = _resolveComponent("Dropdown");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_Dropdown, {
                    key: "weekInput",
                    options: $data.dateTypes,
                    "default-item": $data.dateTypeSelected.name,
                    "input-value": $data.dropdownLabel,
                    "prepend-icon": 'calendar2-week',
                    styling: 'whiteDefaultBorder',
                    "max-item": 5,
                    onSelected: $options.setDateType
                }, null, 8, ["options", "default-item", "input-value", "onSelected"])
            ])
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_Dropdown, {
                    key: "startRange",
                    options: $data.weeksList,
                    "default-item": $data.weekFrom.toString(),
                    "input-value": $data.weekFrom.toString(),
                    "prepend-label": $options.startDateLabel,
                    styling: 'whiteDefaultBorder',
                    onSelected: $options.setStartRange
                }, null, 8, ["options", "default-item", "input-value", "prepend-label", "onSelected"])
            ])
        ], 512), [
            [_vShow, $data.dateTypeSelected.id == 'CW' || $data.dateTypeSelected.id == 'CWR']
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_Dropdown, {
                    key: "endRange",
                    options: $data.weeksList,
                    "default-item": $data.weekTo.toString(),
                    "input-value": $data.weekTo.toString(),
                    "prepend-label": 'To',
                    styling: 'whiteDefaultBorder',
                    onSelected: $options.setEndRange
                }, null, 8, ["options", "default-item", "input-value", "onSelected"])
            ])
        ], 512), [
            [_vShow, $data.dateTypeSelected.id == 'CWR']
        ])
    ], 64));
}
