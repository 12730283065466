import { apiFactory } from "@/api/factories/apiFactory";
class notificationService {
    _notificationApi = apiFactory.useNotificationApi();
    async getNotification() {
        const notification = await this._notificationApi.getNotification();
        if (notification.response && notification.success) {
            return notification.response;
        }
        return {};
    }
    async getMaintenanceNotification() {
        const notification = await this._notificationApi.getMaintenanceNotification();
        if (notification.response && notification.success) {
            return notification.response;
        }
        return {};
    }
    async addNotification(notification) {
        const response = await this._notificationApi.addNotification(notification);
        if (response.success) {
            return true;
        }
        return false;
    }
    async expireNotification(notificationId) {
        const response = await this._notificationApi.expireNotification(notificationId);
        if (response.success) {
            return true;
        }
        return false;
    }
}
export default notificationService;
