import { apiFactory } from "@/api/factories/apiFactory";
class productService {
    _productApi = apiFactory.useProductApi();
    async getProductParentDepartments() {
        const parentDepartments = await this._productApi.getProductParentDepartments();
        if (parentDepartments.response && parentDepartments.success) {
            return parentDepartments.response;
        }
        return [];
    }
    async getProductDepartments() {
        const productDepartments = await this._productApi.getProductDepartments();
        if (productDepartments.response && productDepartments.success) {
            return productDepartments.response;
        }
        return [];
    }
    async getProductGroups() {
        const productGroups = await this._productApi.getProductGroups();
        if (productGroups.response && productGroups.success) {
            return productGroups.response;
        }
        return [];
    }
    async getProductSubGroups() {
        const productSubGroups = await this._productApi.getProductSubGroups();
        if (productSubGroups.response && productSubGroups.success) {
            return productSubGroups.response;
        }
        return [];
    }
    async getProductVariants() {
        const productVariants = await this._productApi.getProductVariants();
        if (productVariants.response && productVariants.success) {
            return productVariants.response;
        }
        return [];
    }
}
export default productService;
