import { apiFactory } from "@/api/factories/apiFactory";
class powerBiService {
    _workspaceApi = apiFactory.useWorkspaceApi();
    _reportApi = apiFactory.useReportApi();
    async getWorkspaces() {
        const workspaces = await this._workspaceApi.getWorkspaces();
        if (workspaces.response && workspaces.success) {
            return workspaces.response;
        }
        return [];
    }
    async getReports(id) {
        const reports = await this._reportApi.getReports(id);
        if (reports.response && reports.success) {
            return reports.response;
        }
        return [];
    }
}
export default powerBiService;
