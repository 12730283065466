import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '@/assets/images/bootstrapIcons';
const _hoisted_1 = _imports_0 + '#diagram-3';
const _hoisted_2 = _imports_0 + '#x-square-fill';
const _hoisted_3 = { class: "filtermenu pe-3 mx-2 mb-2" };
const _hoisted_4 = { class: "input-group input-group-sm" };
const _hoisted_5 = _createElementVNode("div", {
    class: "input-group-prepend rounded-0",
    style: { "min-width": "36px" }
}, [
    _createElementVNode("div", { class: "input-group-text prependLabel p-1" }, [
        _createElementVNode("svg", {
            class: "ms-1 rounded-0",
            width: "18",
            height: "22",
            fill: "currentColor"
        }, [
            _createElementVNode("use", { "xlink:href": _hoisted_1 })
        ])
    ])
], -1);
const _hoisted_6 = { class: "row m-0 p-0 flex-nowrap" };
const _hoisted_7 = { class: "filteritem p-0" };
const _hoisted_8 = {
    key: 0,
    class: "col-1 px-1",
    title: "Clear Selection"
};
const _hoisted_9 = _createElementVNode("use", { "xlink:href": _hoisted_2 }, null, -1);
const _hoisted_10 = [
    _hoisted_9
];
const _hoisted_11 = { class: "row m-0 p-0 flex-nowrap" };
const _hoisted_12 = { class: "filteritem p-0" };
const _hoisted_13 = {
    key: 0,
    class: "col-1 px-1",
    title: "Clear Selection"
};
const _hoisted_14 = _createElementVNode("use", { "xlink:href": _hoisted_2 }, null, -1);
const _hoisted_15 = [
    _hoisted_14
];
const _hoisted_16 = { class: "row m-0 p-0 flex-nowrap" };
const _hoisted_17 = { class: "filteritem p-0" };
const _hoisted_18 = {
    key: 0,
    class: "col-1 px-1",
    title: "Clear Selection"
};
const _hoisted_19 = _createElementVNode("use", { "xlink:href": _hoisted_2 }, null, -1);
const _hoisted_20 = [
    _hoisted_19
];
const _hoisted_21 = { class: "row m-0 p-0 flex-nowrap" };
const _hoisted_22 = { class: "filteritem p-0" };
const _hoisted_23 = {
    key: 0,
    class: "col-1 px-1",
    title: "Clear Selection"
};
const _hoisted_24 = _createElementVNode("use", { "xlink:href": _hoisted_2 }, null, -1);
const _hoisted_25 = [
    _hoisted_24
];
const _hoisted_26 = { class: "row m-0 p-0 flex-nowrap" };
const _hoisted_27 = { class: "filteritem p-0" };
const _hoisted_28 = {
    key: 0,
    class: "col-1 px-1",
    title: "Clear Selection"
};
const _hoisted_29 = _createElementVNode("use", { "xlink:href": _hoisted_2 }, null, -1);
const _hoisted_30 = [
    _hoisted_29
];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Dropdown = _resolveComponent("Dropdown");
    return (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
            _hoisted_5,
            _createElementVNode("div", {
                class: _normalizeClass(['filteritem filterlabel p-1 px-3']),
                onClick: _cache[0] || (_cache[0] =
                    (...args) => ($options.toggleProductSelection && $options.toggleProductSelection(...args)))
            }, _toDisplayString($data.product.currentProductLabel), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("div", _hoisted_7, [
                _createVNode(_component_Dropdown, {
                    ref: "productLevel1Input",
                    key: "productLevel1Input",
                    options: $data.product.filteredParentDepartments,
                    disabled: false,
                    "default-item": $data.product.currentParentDepartment,
                    styling: $options.productParentDepartmentIsActive,
                    name: "productLevel1Name",
                    "max-item": $options.productParentDepartmentsCount,
                    "input-value": $options.productParentDepartment,
                    onSelected: $options.getProductParentDepartmentSelection
                }, null, 8, ["options", "default-item", "styling", "max-item", "input-value", "onSelected"])
            ], 512), [
                [_vShow, $data.showProductBreadcrumb]
            ]),
            ($data.showProductBreadcrumb && $data.product.currentParentDepartment != 'All Chambers')
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_openBlock(), _createElementBlock("svg", {
                        class: "bi bi-x-lg clearsingle",
                        width: "16",
                        height: "16",
                        viewBox: "0 0 16 16",
                        onClick: _cache[1] || (_cache[1] = ($event) => ($options.getProductParentDepartmentSelection($data.product.ParentDepartments[0])))
                    }, _hoisted_10))
                ]))
                : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_11, [
            _withDirectives(_createElementVNode("div", _hoisted_12, [
                _createVNode(_component_Dropdown, {
                    ref: "productLevel2Input",
                    key: "productLevel2Input",
                    options: $data.product.filteredDepartments,
                    disabled: false,
                    "default-item": $data.product.currentDepartment,
                    styling: $options.productDepartmentIsActive,
                    name: "productLevel2Name",
                    "max-item": $options.productDepartmentsCount,
                    "input-value": $options.productDepartment,
                    onSelected: $options.getProductDepartmentSelection
                }, null, 8, ["options", "default-item", "styling", "max-item", "input-value", "onSelected"])
            ], 512), [
                [_vShow, $data.showProductBreadcrumb]
            ]),
            ($data.showProductBreadcrumb && $data.product.currentDepartment != 'All Product Departments')
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    (_openBlock(), _createElementBlock("svg", {
                        class: "bi bi-x-lg clearsingle",
                        width: "16",
                        height: "16",
                        viewBox: "0 0 16 16",
                        onClick: _cache[2] || (_cache[2] = ($event) => ($options.getProductDepartmentSelection($data.product.Departments[0])))
                    }, _hoisted_15))
                ]))
                : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_16, [
            _withDirectives(_createElementVNode("div", _hoisted_17, [
                _createVNode(_component_Dropdown, {
                    ref: "productLevel3Input",
                    key: "productLevel3Input",
                    options: $data.product.filteredGroups,
                    disabled: false,
                    "default-item": $data.product.currentGroup,
                    styling: $options.productGroupIsActive,
                    name: "productLevel3Name",
                    "max-item": $options.productGroupsCount,
                    "input-value": $options.productGroup,
                    onSelected: $options.getProductGroupSelection
                }, null, 8, ["options", "default-item", "styling", "max-item", "input-value", "onSelected"])
            ], 512), [
                [_vShow, $data.showProductBreadcrumb]
            ]),
            ($data.showProductBreadcrumb && $data.product.currentGroup != 'All Product Groups')
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    (_openBlock(), _createElementBlock("svg", {
                        class: "bi bi-x-lg clearsingle",
                        width: "16",
                        height: "16",
                        viewBox: "0 0 16 16",
                        onClick: _cache[3] || (_cache[3] = ($event) => ($options.getProductGroupSelection($data.product.Groups[0])))
                    }, _hoisted_20))
                ]))
                : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_21, [
            _withDirectives(_createElementVNode("div", _hoisted_22, [
                _createVNode(_component_Dropdown, {
                    ref: "productLevel4Input",
                    key: "productLevel4Input",
                    options: $data.product.filteredSubGroups,
                    disabled: false,
                    "default-item": $data.product.currentSubGroup,
                    styling: $options.productSubGroupIsActive,
                    name: "productLevel4Name",
                    "max-item": $options.productSubGroupsCount,
                    "input-value": $options.productSubGroup,
                    onSelected: $options.getProductSubGroupSelection
                }, null, 8, ["options", "default-item", "styling", "max-item", "input-value", "onSelected"])
            ], 512), [
                [_vShow, $data.showProductBreadcrumb]
            ]),
            ($data.showProductBreadcrumb && $data.product.currentSubGroup != 'All Product Sub Groups')
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    (_openBlock(), _createElementBlock("svg", {
                        class: "bi bi-x-lg clearsingle",
                        width: "16",
                        height: "16",
                        viewBox: "0 0 16 16",
                        onClick: _cache[4] || (_cache[4] = ($event) => ($options.getProductSubGroupSelection($data.product.SubGroups[0])))
                    }, _hoisted_25))
                ]))
                : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_26, [
            _withDirectives(_createElementVNode("div", _hoisted_27, [
                _createVNode(_component_Dropdown, {
                    ref: "productLevel6Input",
                    key: "productLevel6Input",
                    options: $data.product.filteredVariantPLUs,
                    disabled: false,
                    "default-item": $data.product.currentVariantPLU,
                    styling: $options.productVariantPLUIsActive,
                    name: "productLevel6Name",
                    "max-item": $options.productVariantPLUsCount,
                    "input-value": $options.productVariantPLU,
                    onSelected: $options.getProductVariantPLUSelection
                }, null, 8, ["options", "default-item", "styling", "max-item", "input-value", "onSelected"])
            ], 512), [
                [_vShow, $data.showProductBreadcrumb]
            ]),
            ($data.showProductBreadcrumb && $data.product.currentVariantPLU != 'All Product Variant PLUs')
                ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                    (_openBlock(), _createElementBlock("svg", {
                        class: "bi bi-x-lg clearsingle",
                        width: "16",
                        height: "16",
                        viewBox: "0 0 16 16",
                        onClick: _cache[5] || (_cache[5] = ($event) => ($options.getProductVariantPLUSelection($data.product.VariantPLUs[0])))
                    }, _hoisted_30))
                ]))
                : _createCommentVNode("", true)
        ])
    ]));
}
