import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "nav d-md-none fixed-bottom bg-light p-1 justify-content-start"
};
import { useAuthStore } from "@/stores/authStore";
import { useApplicationStore } from "@/stores/applicationStore";
import ProfileButton from "./ProfileButton.vue";
import AppsButton from "./AppsButton.vue";
export default _defineComponent({
    __name: 'BottomBar',
    emits: ["logout"],
    setup(__props, { emit }) {
        const authStore = useAuthStore();
        const applicationStore = useApplicationStore();
        return (_ctx, _cache) => {
            return (_unref(authStore).user.isAuthenticated && _unref(applicationStore).menuData != undefined)
                ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
                    _createVNode(ProfileButton, {
                        "profile-details": _unref(applicationStore).menuData.profileDetails,
                        "avatar-only": true,
                        onLogout: _cache[0] || (_cache[0] = ($event) => (emit('logout')))
                    }, null, 8, ["profile-details"]),
                    _createVNode(AppsButton, { "icon-only": true })
                ]))
                : _createCommentVNode("", true);
        };
    }
});
