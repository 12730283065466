import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = _createElementVNode("div", { class: "fw-semibold fs-5 mt-3 mb-1 mx-5 text-center" }, " My Recent Reports ", -1);
const _hoisted_3 = { class: "recent-reports-table" };
const _hoisted_4 = { class: "table table-horizontal mb-0" };
const _hoisted_5 = _createElementVNode("thead", null, [
    _createElementVNode("tr", null, [
        _createElementVNode("th", { class: "col-1" }),
        _createElementVNode("th", { class: "col-7" }, " Display Name "),
        _createElementVNode("th", { class: "col-2 hideOnMobile" }, " Category "),
        _createElementVNode("th", { class: "col-2" }, " Last Viewed ")
    ])
], -1);
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
    class: "bi",
    fill: "#A0202E"
};
const _hoisted_8 = ["xlink:href"];
const _hoisted_9 = ["onClick"];
const _hoisted_10 = ["onClick"];
const _hoisted_11 = ["onClick"];
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import $cookies from "vue-cookies";
import bootstrapIcons from "@/assets/images/bootstrapIcons";
export default _defineComponent({
    __name: 'RecentReports',
    emits: ["collapseSidebar"],
    setup(__props, { expose, emit }) {
        const recentReports = ref(new Array());
        const loading = ref(true);
        const router = useRouter();
        onMounted(() => {
            getRecentReports();
        });
        function getRecentReports() {
            loading.value = false;
            recentReports.value = $cookies.get("recentReports") || new Array();
            sortRecentReports();
            if (recentReports.value && recentReports.value.length > 10) {
                recentReports.value = recentReports.value.slice(0, 10);
            }
        }
        function sortRecentReports() {
            recentReports.value?.sort((a, b) => {
                const dateA = a.lastViewed ? new Date(a.lastViewed) : new Date(0);
                const dateB = b.lastViewed ? new Date(b.lastViewed) : new Date(0);
                return dateB.getTime() - dateA.getTime();
            });
        }
        function openReportPage(page) {
            page.lastViewed = new Date();
            const updatedReports = recentReports.value.map(report => report.reportPageId === page.reportPageId ? page : report);
            if (!recentReports.value.some(report => report.reportPageId === page.reportPageId)) {
                updatedReports.push(page);
            }
            $cookies.set("recentReports", updatedReports, "30d");
            router.push({ path: page.category, query: { pageName: page.reportPageName } });
            getRecentReports();
            emit("collapseSidebar");
        }
        function formatLastOpenedTime(page) {
            const now = new Date();
            const lastViewedDate = page.lastViewed ? new Date(page.lastViewed) : new Date(0);
            const diffInMs = now.getTime() - lastViewedDate.getTime();
            const seconds = Math.floor(diffInMs / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);
            const weeks = Math.floor(days / 7);
            const months = Math.floor(days / 30);
            const years = Math.floor(days / 365);
            if (years > 10)
                return "Never";
            if (years > 0) {
                return years === 1 ? "1 year ago" : `${years} years ago`;
            }
            else if (months > 0) {
                return months === 1 ? "1 month ago" : `${months} months ago`;
            }
            else if (weeks > 0) {
                return weeks === 1 ? "1 week ago" : `${weeks} weeks ago`;
            }
            else if (days > 0) {
                return days === 1 ? "1 day ago" : `${days} days ago`;
            }
            else if (hours > 0) {
                return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
            }
            else if (minutes > 0) {
                return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
            }
            else {
                return seconds === 1 ? "1 second ago" : `${seconds} seconds ago`;
            }
        }
        expose({ getRecentReports });
        return (_ctx, _cache) => {
            return (recentReports.value && recentReports.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _hoisted_2,
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("table", _hoisted_4, [
                            _hoisted_5,
                            _createElementVNode("tbody", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(recentReports.value, (page) => {
                                    return (_openBlock(), _createElementBlock("tr", {
                                        key: page.reportPageId,
                                        style: { "line-height": "35px" }
                                    }, [
                                        _createElementVNode("td", {
                                            class: "col-1 text-center",
                                            onClick: ($event) => (openReportPage(page))
                                        }, [
                                            (_openBlock(), _createElementBlock("svg", _hoisted_7, [
                                                _createElementVNode("use", {
                                                    "xlink:href": `${_unref(bootstrapIcons)}#${page.icon}`
                                                }, null, 8, _hoisted_8)
                                            ]))
                                        ], 8, _hoisted_6),
                                        _createElementVNode("td", {
                                            class: "col-7",
                                            onClick: ($event) => (openReportPage(page))
                                        }, _toDisplayString(page.displayName), 9, _hoisted_9),
                                        _createElementVNode("td", {
                                            class: "col-2 hideOnMobile",
                                            onClick: ($event) => (openReportPage(page))
                                        }, _toDisplayString(page.category), 9, _hoisted_10),
                                        _createElementVNode("td", {
                                            class: "col-2",
                                            onClick: ($event) => (openReportPage(page))
                                        }, _toDisplayString(formatLastOpenedTime(page)), 9, _hoisted_11)
                                    ]));
                                }), 128))
                            ])
                        ])
                    ])
                ]))
                : _createCommentVNode("", true);
        };
    }
});
