import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "wrapper" };
const _hoisted_2 = { class: "progress-bar" };
const _hoisted_3 = _createElementVNode("div", { class: "spinnerWrapper" }, [
    _createElementVNode("span", { class: "loader" })
], -1);
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(['loadingOverlay', $props.isTransparent ? 'transparent' : ''])
    }, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", {
                    class: _normalizeClass(['progress-bar-fill', $data.loadingBarClass])
                }, null, 2)
            ])
        ]),
        _hoisted_3
    ], 2)), [
        [_vShow, $data.isLoading]
    ]);
}
