<script setup>
 
// Assets
import bootstrapIcons from "@/assets/images/bootstrapIcons"

</script>

<template>
  <div class="dropdown" v-if="options">
    <!-- Dropdown Input -->
    <div>
      <div class="input-group input-group-sm">
        <div v-show="prependLabel || prependIcon" class="input-group-prepend rounded-0">
          <div class="input-group-text prependLabel p-1">
            <svg v-show="prependIcon" class="ms-1 rounded-0" width="18" height="22" fill="currentColor">
              <use :xlink:href="`${bootstrapIcons}#${prependIcon}`" />
            </svg>
            {{prependLabel}}&nbsp;
          </div>
        </div> 
        <input  
          :id="id" 
          :value="searchFilter"
          :name="name" 
          :disabled="disabled"
          autocomplete="off"
          placeholder="Type to search..."
          type="text" 
          :class="['form-control', 'dropdown-input', 'rounded-0', styling]" 
          :style="{ width: inputDynamicWidth }" 
          @focus="showOptions"
          @blur="exit"
          @keyup="keyMonitor"
          @input="updateSearchFilter"
        />
      </div>
    </div>

    <!-- Dropdown Menu -->
    <div 
      v-show="optionsShown"
      class="dropdown-content">
      <div
        v-for="(option, index) in filteredOptions"
        :key="index"
        :class="['dropdown-item']"
        @mousedown="selectOption(option)">
        <svg v-show="option.icon!=undefined" class="ms-1 rounded-0" width="18" height="22" fill="currentColor">
          <use :xlink:href="`${bootstrapIcons}#${option.icon}`" />
        </svg> 
        {{ option.name || option.id || '-' }}
      </div>
    </div>
  </div>
</template>

<script>
   
export default {
  name: "Dropdown",
  props: {
    id: {
      type: String,
      required: false,
      default: "dropdown",
      note: "Input ID"
    },
    name: {
      type: String,
      required: false,
      default: "dropdown",
      note: "Input name"
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
      note: "Options of dropdown. An array of options with id and name",
    },
    inputValue: {
      type: String,
      required: false,
      default: "Please select an option",
      note: "input value of dropdown"
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: "Disable the dropdown"
    },
    maxItem: {
      type: Number,
      required: false,
      default: 100,
      note: "Max items showing"
    },
    defaultItem: {
      type: String,
      required: false,
      default: "",
      note: "Default value to display instead of inputValue"
    },
    styling: {
      type: String,
      required: false,
      default: "whiteDefault",
      note: "Show different styling. Options are 'Active', 'Inactive', or 'None'. If left blank will show default white dropdown."
    },
    prependLabel: {
      type: String,
      required: false,
      default: undefined,
      note: "Show a label for the dropdown."
    },
    prependIcon: {
      type: String,
      required: false,
      default: undefined,
      note: "Show a bootstrap svg icon for the dropdown."
    },
  },
  emits: [ "selected", "filter" ],
  data () {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: this.defaultItem || "",
      oldSelection: ""
    }
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, "ig");
      for (const option of this.options) {
        if (this.searchFilter.length < 1 || option.name.match(regOption)) {
          if (filtered.length < this.maxItem) filtered.push(option);
        }
      }
      return filtered;
    },
    inputDynamicWidth() {
      return (this.inputValue.length + 6) + "ch"
    }
  },
  watch: {
    searchFilter() {
      if (this.filteredOptions.length === 0) {
        // this.selected = {};
      } else {
        this.selected = this.filteredOptions[0];
      }
      this.$emit("filter", this.searchFilter);
    },
    options(value, oldValue) {
      if(value !== oldValue) {
        this.searchFilter = this.inputValue;
        this.oldSelection = this.inputValue;
      }
    },
    inputValue(value, oldValue) {
      if(value !== oldValue) {
        this.searchFilter = value;
        this.oldSelection = value;
      }      
    }
  },
  methods: {
    updateSearchFilter(event){
      this.searchFilter = event.target.value;
    },
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.name;
      this.$emit("selected", this.selected);
    },
    showOptions() {
      if (!this.disabled) {
        this.oldSelection = this.searchFilter;
        this.searchFilter = "";
        this.optionsShown = true;
      }
    },
    exit() {
      if (!this.selected.id) {
        // Don't close dropdown if the scrollbar is clicked
        return;
		
      } else {
        if (!this.searchFilter || this.searchFilter.length === 0) {
          this.searchFilter = this.oldSelection;
          this.optionsShown = false;
        } else {
          this.searchFilter = this.inputValue;
          this.optionsShown = false;
        }        
      }
    },
									
    keyMonitor(event) {
      if (event.key === "Enter" && this.filteredOptions[0]) {
        this.selectOption(this.filteredOptions[0]);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
    .dropdown {
        .dropdown-input {
            background: #363636;
            cursor: pointer;
            font-size: 14px;
            text-align: center;
            color: #fff;
            box-shadow: none !important;

            &:hover {
                background: #D0202E;
                color: #fff;
            }

            &:focus {
                background: #fff;
                color: black;
            }
        }
        .input-group-prepend {    
          border:rgb(204, 204, 204) solid 1px;
          padding-top: 0;
          padding-bottom: 0;
          font-size: 14px;
          min-width: 36px;
        }

        .dropdown-content {
            //position: absolute;
            background-color: #fff;
            min-width: 128px;
            max-height: 248px;
            border: 1px solid #e0e0e0;
            box-shadow: 0px -8px 34px 0px rgba(0,0,0,0.05);
            overflow: auto;
            overflow-x: hidden;
            z-index: 1;
            text-align: center;
            .dropdown-item {
                color: black;
                font-size: 14px;
                line-height: 1em;
                padding: 6px 8px 6px 8px;
                text-decoration: none;
                display: block;
                cursor: pointer;

                &:hover {
                  background-color: #D0202E;
                  color: #fff;
                }
            }
        }

        .whiteDefault{
            background: #fff;
            color: black;

            &:hover {
                background-color: #fff;
                color: #363636
            }
        }

        .whiteDefaultBorder{
            background: #fff;
            color: black;
            border: #ccc 1px solid;

            &:hover {
                border: grey 1px solid;
                background-color: #fff;
                color: #363636
            }
        }

        .Active {
            background: #D0202E;
            color: #fff;
        }

        .Inactive {
            background: #999;
            color: #fff;
        }

        .None {
          border: none;
        }

        .dropdown:hover .dropdowncontent {
            display: block;
        }
    }

    


</style>