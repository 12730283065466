import FilterManager from "@/components/report/FilterManager.vue";
import LocationFilter from "@/components/report/LocationFilter.vue";
import ProductFilter from "@/components/report/ProductFilter.vue";
import WeekRangePicker from "@/components/root/weekRangePicker.vue";
import DateFilter from "@/components/report/DateFilter.vue";
import HomePage from "./HomePage.vue";
import embeddedTokenService from "@/services/application/embeddedTokenService";
import routeReportService from "@/services/application/routeReportService";
import pageChangeService from "@/services/application/pageChangeService";
import defaultTheme from "@/assets/jsonThemes/DefaultTheme.json";
const embeddedTokenSvc = new embeddedTokenService();
const routeReportSvc = new routeReportService();
const pageChangeSvc = new pageChangeService();
export default {
    name: "ReportView",
    components: {
        FilterManager,
        LocationFilter,
        ProductFilter,
        WeekRangePicker,
        DateFilter,
        HomePage
    },
    async beforeRouteLeave(to, from, next) {
        await this.delay(100);
        if (to != from && this.backButtonClicked) {
            this.backButtonClicked = false;
            next(false);
        }
        else {
            this.backButtonClicked = false;
            next();
        }
    },
    emits: ["collapseSidebar"],
    data() {
        return {
            embeddedToken: undefined,
            embeddedTokenMinutesToExpiration: undefined,
            embeddedTokenAquireTime: undefined,
            reportList: [],
            currentReportId: "",
            firstReportId: "",
            bookmark: undefined,
            location: { hierarchyLevel: "Trading Status", tradingStatus: "Trading Stores", region: "All Regions", area: "All Areas", store: "All Stores" },
            oldLocation: { hierarchyLevel: "Trading Status", tradingStatus: "Trading Stores", region: "All Regions", area: "All Areas", store: "All Stores" },
            newRegion: undefined,
            newArea: undefined,
            newStore: undefined,
            newProductParentDepartment: undefined,
            newProductDepartment: undefined,
            newProductGroup: undefined,
            newProductSubGroup: undefined,
            newProductParentPLU: undefined,
            newProductVariantPLU: undefined,
            product: {
                hierarchyLevel: "AllProducts",
                parentDepartment: "All Chambers",
                department: "All Product Departments",
                group: "All Product Groups",
                subGroup: "All Product Sub Groups",
                variantPLU: "All Product Variant PLUs"
            },
            oldProduct: {
                hierarchyLevel: "AllProducts",
                parentDepartment: "All Chambers",
                department: "All Product Departments",
                group: "All Product Groups",
                subGroup: "All Product Sub Groups",
                variantPLU: "All Product Variant PLUs"
            },
            currentTheme: defaultTheme,
            weeks: { from: undefined, to: undefined, isCurrentWeek: false },
            oldWeeks: { from: undefined, to: undefined, isCurrentWeek: false },
            date: "",
            showLocationFilter: false,
            previousPageLocationFilterShown: undefined,
            showProductFilter: false,
            previousPageProductFilterShown: undefined,
            showWeekFilter: false,
            previousPageWeekFilterShown: undefined,
            showDateFilter: false,
            previousPageDateFilterShown: undefined,
            currentPage: undefined,
            pageData: undefined,
            backButtonClicked: false,
            openPageIndex: undefined,
            routeReports: [],
            pageName: "",
            firstPage: "",
            newReportCookie: undefined,
            resetDateFilter: false,
            setDateFilter: false
        };
    },
    computed: {
        isHomePage() {
            return this.$route.path === "/home";
        }
    },
    watch: {
        async "$route"() {
            this.bookmark = await this.$route.query.bookmark;
            this.pageData = await this.$route.meta.pages;
            this.pageName = await this.$route.query.pageName;
            this.firstPage = await this.$route.query.firstPage;
            if (!this.isHomePage) {
                let routeReport = this.routeReports.find(rr => rr.routeName === this.$route.name);
                this.currentReportId = routeReport ? routeReport.reportId : "";
            }
            await this.updateReportList(this.currentReportId);
        }
    },
    beforeUnmount() {
        window.removeEventListener("popstate", this.handlePopstate);
    },
    async created() {
        window.addEventListener("popstate", this.handlePopstate);
        await this.getRouteReportIDs();
        let routeReport = this.routeReports.find(rr => rr.routeName === this.$route.name);
        this.currentReportId = routeReport ? routeReport.reportId : "";
        await this.updateReportList(this.currentReportId);
    },
    methods: {
        async getRouteReportIDs() {
            await (this.routeReports = await routeReportSvc.getRouteReports());
        },
        async handlePopstate(event) {
            this.openPageIndex = 0;
            this.backButtonClicked = true;
            history.pushState(null, "", window.location.href);
            await this.delay(100);
        },
        async updateFilterVisibilityWeek(pageFilters) {
            if (pageFilters?.showWeekFilter === undefined)
                this.showWeekFilter = true;
            else
                this.showWeekFilter = pageFilters?.showWeekFilter;
            if (this.previousPageWeekFilterShown === undefined)
                this.previousPageWeekFilterShown = this.showWeekFilter;
        },
        async updateFilterVisibilityLocation(pageFilters) {
            if (pageFilters?.showLocationFilter === undefined)
                this.showLocationFilter = true;
            else
                this.showLocationFilter = pageFilters?.showLocationFilter;
            if (this.previousPageLocationFilterShown === undefined)
                this.previousPageLocationFilterShown = this.showLocationFilter;
        },
        async updateFilterVisibilityProduct(pageFilters) {
            if (pageFilters?.showProductFilter === undefined)
                this.showProductFilter = true;
            else
                this.showProductFilter = pageFilters?.showProductFilter;
            if (this.previousPageProductFilterShown === undefined)
                this.previousPageProductFilterShown = this.showProductFilter;
        },
        async updateFilterVisibilityDate(pageFilters) {
            if (pageFilters?.showDateFilter === undefined)
                this.showDateFilter = false;
            else
                this.showDateFilter = pageFilters?.showDateFilter;
            if (this.previousPageDateFilterShown === undefined)
                this.previousPageDateFilterShown = this.showDateFilter;
        },
        async updateFilterVisibilityStatus(pageFilters) {
            this.updateFilterVisibilityWeek(pageFilters);
            this.updateFilterVisibilityLocation(pageFilters);
            this.updateFilterVisibilityProduct(pageFilters);
            this.updateFilterVisibilityDate(pageFilters);
        },
        async pageChanged(newPage) {
            if (this.currentPage && (this.currentPage.displayName !== newPage.displayName)) {
                pageChangeSvc.pageChange(this.currentPage.displayName, newPage.displayName, this.$route.fullPath);
                this.resetDateFilter = this.currentPage?.displayName === "Hourly Sales";
                this.setDateFilter = newPage?.displayName === "Hourly Sales";
            }
            this.openPageIndex = undefined;
            this.currentPage = newPage;
            this.pageData = await this.$route.meta.pages;
            const pageFilters = await this.pageData?.find(p => p.displayName == newPage.displayName);
            await this.updateFilterVisibilityStatus(pageFilters);
            this.bookmark = await this.$route.query.bookmark;
        },
        async updateReportList(newReportId) {
            if (this.embeddedToken == undefined)
                this.getEmbeddedToken();
            if (this.reportList.some(report => report.id == newReportId))
                return;
            if (this.reportList.length == 0)
                this.firstReportId = newReportId;
            await this.reportList.push({ id: newReportId });
            this.bookmark = await this.$route.query.bookmark;
        },
        async getEmbeddedToken() {
            await this.aquireEmbeddedToken();
            this.waitForRefreshToken(this.embeddedTokenMinutesToExpiration, this.embeddedTokenAquireTime);
        },
        async aquireEmbeddedToken() {
            const embedTokenResponse = await embeddedTokenSvc.findEmbeddedToken("svc-powerbi@iceland.co.uk");
            const embeddedToken = await JSON.parse(JSON.stringify(embedTokenResponse));
            this.embeddedToken = embeddedToken.EmbedToken;
            this.embeddedTokenMinutesToExpiration = embeddedToken.TokenMinutesToExpiration;
            this.embeddedTokenAquireTime = await new Date();
        },
        async waitForRefreshToken(tokenMinutesToExpiration, tokenAquireTime) {
            let now = new Date();
            let diffMins = 0;
            while (diffMins <= tokenMinutesToExpiration - 5) {
                now = new Date();
                diffMins = Math.round((((now.getTime() - tokenAquireTime.getTime()) % 86400000) % 3600000) / 60000);
                await this.delay(60000);
            }
            this.getEmbeddedToken();
        },
        async until(conditionFunction) {
            const poll = resolve => {
                if (conditionFunction())
                    resolve();
                else
                    setTimeout(_ => poll(resolve), 400);
            };
            return new Promise(poll);
        },
        delay(ms) { return new Promise(resolve => setTimeout(resolve, ms)); },
        selectedWeekFilters(weeks) { this.weeks = weeks; },
        selectedLocation(newLocation) { this.location = newLocation; },
        selectedProduct(newProducts) { this.product = newProducts; },
        selectedDate(newDate) { this.date = newDate; },
        regionChanged(region) { this.newRegion = region; },
        areaChanged(area) { this.newArea = area; },
        storeChanged(store) { this.newStore = store; },
        parentDepartmentChanged(parentDepartment) { this.newProductParentDepartment = parentDepartment; },
        departmentChanged(department) { this.newProductDepartment = department; },
        groupChanged(group) { this.newProductGroup = group; },
        subGroupChanged(subGroup) { this.newProductSubGroup = subGroup; },
        variantPLUChanged(variantPLU) { this.newProductVariantPLU = variantPLU; },
        selectedTheme(theme) { this.currentTheme = theme.json; },
        updateRecentReportsCookie(newReport) { this.newReportCookie = newReport; },
        locationFilterReset(selectionType) {
            switch (selectionType) {
                case "Trading Status":
                    this.newTradingStatus = "Trading Stores";
                    this.newRegion = "All Regions";
                    this.newArea = "All Areas";
                    this.newStore = "All Stores";
                    break;
                case "Region":
                    this.newRegion = "All Regions";
                    this.newArea = "All Areas";
                    this.newStore = "All Stores";
                    break;
                case "Area":
                    this.newArea = "All Areas";
                    this.newStore = "All Stores";
                    break;
                case "Store":
                    this.newStore = "All Stores";
                    break;
            }
        },
        productFilterReset(selectionType) {
            switch (selectionType) {
                case "ProductLevel1":
                    this.newProductParentDepartment = "All Chambers";
                    this.newProductDepartment = "All Product Departments";
                    this.newProductGroup = "All Product Groups";
                    this.newProductSubGroup = "All Product Sub Groups";
                    this.newProductVariantPLU = "All Product Variant PLUs";
                    break;
                case "ProductLevel2":
                    this.newProductDepartment = "All Product Departments";
                    this.newProductGroup = "All Product Groups";
                    this.newProductSubGroup = "All Product Sub Groups";
                    this.newProductVariantPLU = "All Product Variant PLUs";
                    break;
                case "ProductLevel3":
                    this.newProductGroup = "All Product Groups";
                    this.newProductSubGroup = "All Product Sub Groups";
                    this.newProductVariantPLU = "All Product Variant PLUs";
                    break;
                case "ProductLevel4":
                    this.newProductSubGroup = "All Product Sub Groups";
                    this.newProductVariantPLU = "All Product Variant PLUs";
                    break;
                case "ProductLevel6":
                    this.newProductVariantPLU = "All Product Variant PLUs";
                    break;
            }
        },
        handleCollapseSidebar() {
            this.$emit("collapseSidebar");
        },
    },
};
