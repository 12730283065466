import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "container py-4" };
const _hoisted_2 = { class: "p-5 mb-4 bg-light rounded-3 text-center" };
const _hoisted_3 = _createElementVNode("h1", { class: "display-5 fw-bold" }, " Signed Out ", -1);
const _hoisted_4 = _createElementVNode("p", { class: "col-md fs-4" }, " You are now signed out. ", -1);
import { useRouter } from "vue-router";
export default _defineComponent({
    __name: 'SignedOutView',
    setup(__props) {
        const router = useRouter();
        function redirectToLandingPage() {
            router.push({ name: "landingPage" });
        }
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _hoisted_3,
                    _hoisted_4,
                    _createElementVNode("button", {
                        class: "btn btn-primary btn-lg",
                        onClick: _cache[0] || (_cache[0] = ($event) => (redirectToLandingPage()))
                    }, " Sign back in ")
                ])
            ]));
        };
    }
});
