import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_loadingOverlay = _resolveComponent("loadingOverlay");
    const _component_PowerBIReportEmbed = _resolveComponent("PowerBIReportEmbed");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_loadingOverlay, {
            "is-overlay": $data.loadingShown,
            "is-transparent": $data.loadingTransparent
        }, null, 8, ["is-overlay", "is-transparent"]),
        ($data.isEmbedded)
            ? (_openBlock(), _createBlock(_component_PowerBIReportEmbed, {
                key: $props.reportId,
                "embed-config": $data.reportConfig,
                "phased-embedding": $data.phasedEmbeddingFlag,
                "css-class-name": $data.reportClass,
                "event-handlers": $data.eventHandlersMap,
                onReportObj: $options.setReportObj
            }, null, 8, ["embed-config", "phased-embedding", "css-class-name", "event-handlers", "onReportObj"]))
            : _createCommentVNode("", true)
    ], 64));
}
