import apiResponse from "@/models/base/apiResponse";
import { httpClient } from "@/helpers/httpClient";
export default class notificationApi {
    resource = "/api/application/notification";
    async getNotification() {
        const response = new apiResponse(async () => await httpClient.get(`${this.resource}/active`));
        await response.ready;
        return response;
    }
    async getMaintenanceNotification() {
        const response = new apiResponse(async () => await httpClient.get(`${this.resource}/maintenance`));
        await response.ready;
        return response;
    }
    async addNotification(notification) {
        const response = new apiResponse(async () => await httpClient.post(`${this.resource}/add`, notification));
        await response.ready;
        return response;
    }
    async expireNotification(notificationId) {
        const response = new apiResponse(async () => await httpClient.patch(`${this.resource}/expire`, notificationId.toString(), new Date()));
        await response.ready;
        return response;
    }
}
