import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-42fa8f62"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "nav nav-pills flex-column" };
const _hoisted_2 = { class: "nav-item align-items-center" };
const _hoisted_3 = ["width", "height"];
const _hoisted_4 = ["xlink:href"];
const _hoisted_5 = { key: 0 };
import bootstrapIcons from "@/assets/images/bootstrapIcons";
import { computed } from "vue";
export default _defineComponent({
    __name: 'AppsButton',
    props: {
        iconOnly: { type: Boolean }
    },
    setup(__props) {
        const props = __props;
        const iconDimensions = computed(() => props.iconOnly ?? false ? 18 : 25);
        return (_ctx, _cache) => {
            const _component_RouterLink = _resolveComponent("RouterLink");
            return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
                _createElementVNode("li", _hoisted_2, [
                    _createVNode(_component_RouterLink, {
                        id: "app_link",
                        to: { name: 'apps' },
                        class: "d-flex nav-link link-light align-items-center text-decoration-none"
                    }, {
                        default: _withCtx(() => [
                            (_openBlock(), _createElementBlock("svg", {
                                class: _normalizeClass(__props.iconOnly ?? true ? '' : 'me-2'),
                                width: _unref(iconDimensions),
                                height: _unref(iconDimensions),
                                fill: "currentColor"
                            }, [
                                _createElementVNode("use", {
                                    "xlink:href": `${_unref(bootstrapIcons)}#grid-3x3-gap-fill`
                                }, null, 8, _hoisted_4)
                            ], 10, _hoisted_3)),
                            (!__props.iconOnly ?? true)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Applications"))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    })
                ])
            ]));
        };
    }
});
