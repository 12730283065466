import * as pbi from "powerbi-client";
import { PowerBIReportEmbed } from "powerbi-client-vue-js";
import "powerbi-report-authoring";
import loadingOverlay from "@/components/root/LoadingOverlay.vue";
const phasedEmbeddingFlag = false;
const reportClass = "embed-container col-lg-offset-4 col-lg-7 col-md-offset-5 col-md-7 col-sm-offset-5 col-sm-7";
const __default__ = {
    name: "ReportView",
    components: {
        PowerBIReportEmbed,
        loadingOverlay
    },
    props: {
        embeddedToken: String,
        reportId: String,
        theme: Object,
        eventHandlers: { type: Map, default: new Map([["loaded", () => { }]]) }
    },
    emits: ["rendered", "loaded", "embedded", "bookmarkLoaded"],
    data() {
        return {
            reportIdToOpen: this.reportId,
            isEmbedded: false,
            isRendered: false,
            isLoaded: false,
            showReportContainer: false,
            isFilterChanging: false,
            debouncedWidth: 0,
            widthTimeout: null,
            loadingShown: false,
            loadingTransparent: true,
            reportConfig: {
                type: "report",
                embedUrl: undefined,
                tokenType: pbi.models.TokenType.Embed,
                accessToken: undefined,
                settings: {
                    filterPaneEnabled: false,
                    navContentPaneEnabled: false,
                    hideErrors: true,
                    background: pbi.models.BackgroundType.Transparent,
                    visualRenderedEvents: false,
                    layoutType: pbi.models.LayoutType.MobileLandscape
                }
            },
            reportContainerWidth: "1420px",
            reportPageRatio: 1,
            eventHandlersMap: new Map([
                ...this.eventHandlers,
                ["loaded", () => {
                        this.updateLoadingOverlay(true, true);
                        this.checkScreenSize();
                        this.updateContextMenu();
                        this.isLoaded = true;
                        this.$emit("loaded", true);
                    }],
                ["rendered", () => {
                        setTimeout(() => {
                            this.updateLoadingOverlay(false, true);
                            this.isRendered = true;
                            this.$emit("rendered", this.isRendered);
                        }, 500);
                    }],
            ]),
            report: pbi.Report,
            reportClass,
            phasedEmbeddingFlag
        };
    },
    computed: {
        width: {
            get() {
                return this.debouncedWidth;
            },
            set(val) {
                if (this.widthTimeout)
                    clearTimeout(this.widthTimeout);
                this.widthTimeout = setTimeout(() => {
                    this.debouncedWidth = val;
                }, 500);
            },
        },
    },
    watch: {
        embeddedToken: {
            handler: async function (newToken, oldToken) {
                if (newToken == undefined)
                    return;
                if (oldToken == undefined) {
                    this.embedReport(this.reportId, newToken);
                    return;
                }
                if (oldToken != newToken) {
                    this.refreshToken(newToken);
                }
            },
            deep: true,
            immediate: true
        },
        theme(oldTheme, newTheme) {
            if (oldTheme == newTheme || newTheme == undefined)
                return;
            this.setTheme();
        },
    },
    async created() {
        this.updateLoadingOverlay(true, false);
        window.addEventListener("resize", this.resizeHandler);
        this.width = window.innerWidth;
    },
    methods: {
        async updateContextMenu() {
            const newSettings = {
                commands: [
                    {
                        drill: { displayOption: pbi.models.CommandDisplayOption.Hidden },
                        drillthrough: { displayOption: pbi.models.CommandDisplayOption.Hidden },
                        expandCollapse: { displayOption: pbi.models.CommandDisplayOption.Hidden },
                        exportData: { displayOption: pbi.models.CommandDisplayOption.Hidden },
                        includeExclude: { displayOption: pbi.models.CommandDisplayOption.Hidden },
                        seeData: { displayOption: pbi.models.CommandDisplayOption.Hidden },
                        clearSelection: { displayOption: pbi.models.CommandDisplayOption.Enabled }
                    }
                ]
            };
            try {
                const response = await this.report.updateSettings(newSettings);
                return response;
            }
            catch (error) {
                console.log(error);
                return;
            }
        },
        async updateLoadingOverlay(showOverlay, isTransparent) {
            if (isTransparent !== undefined)
                this.loadingTransparent = isTransparent;
            this.loadingShown = showOverlay;
        },
        async setBookmark(bookmarkToSet) {
            this.updateLoadingOverlay(true, false);
            let bookmarks = await this.report.bookmarksManager.getBookmarks();
            const bookmark = await bookmarks.find((bookmark) => bookmark.displayName == bookmarkToSet);
            if (bookmark == undefined)
                return;
            await this.report.bookmarksManager.apply(bookmark.name);
            this.$emit("bookmarkLoaded", true);
        },
        async setTheme() {
            this.updateLoadingOverlay(true, false);
            await this.report.applyTheme({ themeJson: JSON.parse(JSON.stringify(this.theme)) });
            this.$emit("themeLoaded", true);
        },
        async getReportPages() {
            await this.until(_ => this.isLoaded);
            let reportPages = await this.report.getPages();
            let currentPage = await reportPages.find((page) => page.isActive);
            let pageWidth = currentPage.defaultSize.width;
            let pageHeight = currentPage.defaultSize.height;
            if (pageWidth && pageHeight)
                this.reportPageRatio = pageWidth / pageHeight;
            this.checkScreenSize();
            return reportPages;
        },
        async setReportPage(page) {
            this.updateLoadingOverlay(true);
            await page.setActive();
            this.checkScreenSize();
        },
        async clearAllSelections(page) {
            this.updateLoadingOverlay(true);
            let reportPages = await this.getReportPages();
            let currentPage = await reportPages.find((page) => page.isActive);
            await currentPage.setActive();
        },
        async setReportRemoveFilters() {
            this.updateLoadingOverlay(true);
            await this.until(_ => this.isLoaded);
            await this.report.updateFilters(0);
        },
        async setReportAddFilters(filter) {
            this.updateLoadingOverlay(true);
            await this.until(_ => this.isLoaded);
            await this.report.updateFilters(2, filter);
        },
        async setReportReplaceFilters(filter) {
            this.updateLoadingOverlay(true);
            await this.until(_ => this.isLoaded && !this.isFilterChanging);
            this.isFilterChanging = true;
            await this.report.updateFilters(3, filter);
            this.isFilterChanging = false;
        },
        async setReportReplaceAllFilters(filter) {
            this.updateLoadingOverlay(true);
            await this.until(_ => this.isLoaded);
            await this.report.updateFilters(1, filter);
        },
        async getPageVisuals(page) {
            await this.until(_ => this.isLoaded);
            return await page.getVisuals();
        },
        async setPageRemoveVisual(page, visualName) {
            return await page.deleteVisual(visualName);
        },
        async setVisualAddField(visual, dataRole, newField) {
            this.updateLoadingOverlay(true);
            await visual.addDataField(dataRole, newField);
        },
        async setVisualRemoveField(visual, dataRole, oldFieldPosition) {
            this.updateLoadingOverlay(true);
            await visual.removeDataField(dataRole, oldFieldPosition);
        },
        async setVisualReplaceField(visual, dataRole, oldFieldPosition, newField) {
            this.updateLoadingOverlay(true);
            await this.setVisualRemoveField(visual, dataRole, oldFieldPosition);
            await this.setVisualAddField(visual, dataRole, newField);
        },
        setReportObj(value) {
            this.report = value;
        },
        async embedReport(reportId, embeddedToken) {
            try {
                this.updateLoadingOverlay(true, false);
                const embeddedTokenURL = "https://app.powerbi.com/reportEmbed?reportId=" + reportId + "groupId=391add43-b08a-4e5f-874e-5f49e47f26ab&w=2";
                this.reportConfig = {
                    ...this.reportConfig,
                    id: reportId,
                    embedUrl: embeddedTokenURL,
                    accessToken: embeddedToken,
                    ...(this.theme ? { theme: { themeJson: JSON.parse(JSON.stringify(this.theme)) } } : {})
                };
                this.isEmbedded = true;
                this.$emit("embedded", true);
            }
            catch (error) {
                console.log("Embed Report Error");
                console.log(error);
            }
        },
        async refreshToken(embeddedToken) {
            try {
                await this.report.setAccessToken(embeddedToken);
            }
            catch (error) {
                console.log("Unable to aquire refresh token.");
                console.log(error);
            }
        },
        async until(conditionFunction) {
            const poll = resolve => {
                if (conditionFunction())
                    resolve();
                else
                    setTimeout(_ => poll(resolve), 400);
            };
            return new Promise(poll);
        },
        reportAvailable() {
            if (!this.report) {
                return false;
            }
            return true;
        },
        resizeHandler(e) {
            this.width = window.innerWidth;
            this.checkScreenSize();
        },
        async checkScreenSize() {
            if (!this.reportAvailable() || !this.isEmbedded) {
                return;
            }
            await this.until(_ => this.isLoaded);
            let settings;
            if (document.documentElement.clientWidth < 800) {
                settings = {
                    layoutType: pbi.models.LayoutType.MobilePortrait
                };
            }
            else {
                settings = {
                    layoutType: pbi.models.LayoutType.MobileLandscape
                };
            }
            try {
                let windowHeight = window.innerHeight - 35;
                if (windowHeight <= 800)
                    windowHeight = 800;
                this.reportContainerWidth = (windowHeight * this.reportPageRatio) + "px";
                const response = await this.report.updateSettings(settings);
                return response;
            }
            catch (error) {
                console.log(error);
                return;
            }
        },
    },
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
    _useCssVars(_ctx => ({
        "2c3852ed": (_ctx.reportContainerWidth)
    }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__
    ? (props, ctx) => { __injectCSSVars__(); return __setup__(props, ctx); }
    : __injectCSSVars__;
export default __default__;
