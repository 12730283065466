import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue";
import _imports_0 from '@/assets/images/bootstrapIcons';
const _hoisted_1 = _imports_0 + '#funnel';
const _hoisted_2 = {
    class: "position-fixed m-1 ms-5",
    style: { "z-index": "98", "width": "calc(100% - 50px)" }
};
const _hoisted_3 = { class: "navbar navbar-expand-lg py-0 px-4 float-lg-start float-end" };
const _hoisted_4 = _createElementVNode("a", {
    class: "d-lg-none",
    type: "button",
    "data-bs-toggle": "offcanvas",
    "data-bs-target": "#navbarOffcanvasLg",
    "aria-controls": "navbarOffcanvasLg"
}, [
    _createElementVNode("svg", {
        class: "ms-1",
        width: "25",
        height: "32",
        fill: "currentColor"
    }, [
        _createElementVNode("use", { "xlink:href": _hoisted_1 })
    ])
], -1);
const _hoisted_5 = {
    id: "navbarOffcanvasLg",
    class: "offcanvas offcanvas-end",
    tabindex: "-1",
    "aria-labelledby": "navbarOffcanvasLgLabel"
};
const _hoisted_6 = _createElementVNode("div", { class: "offcanvas-header" }, [
    _createElementVNode("h5", {
        id: "offcanvasNavbarLabel",
        class: "offcanvas-title"
    }, " Filters "),
    _createElementVNode("button", {
        type: "button",
        class: "btn-close",
        "data-bs-dismiss": "offcanvas",
        "aria-label": "Close"
    })
], -1);
const _hoisted_7 = { class: "filtermenu d-lg-block" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_HomePage = _resolveComponent("HomePage");
    const _component_LocationFilter = _resolveComponent("LocationFilter");
    const _component_ProductFilter = _resolveComponent("ProductFilter");
    const _component_WeekRangePicker = _resolveComponent("WeekRangePicker");
    const _component_DateFilter = _resolveComponent("DateFilter");
    const _component_FilterManager = _resolveComponent("FilterManager");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _withDirectives(_createVNode(_component_HomePage, {
            "new-report-cookie": $data.newReportCookie,
            onCollapseSidebar: $options.handleCollapseSidebar
        }, null, 8, ["new-report-cookie", "onCollapseSidebar"]), [
            [_vShow, $options.isHomePage]
        ]),
        _withDirectives(_createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                    _hoisted_4,
                    _createElementVNode("div", _hoisted_5, [
                        _hoisted_6,
                        _createElementVNode("div", _hoisted_7, [
                            _withDirectives(_createElementVNode("span", null, [
                                _createVNode(_component_LocationFilter, {
                                    ref: "locationFilter",
                                    region: $data.newRegion,
                                    area: $data.newArea,
                                    store: $data.newStore,
                                    onLocation: $options.selectedLocation,
                                    onLocationFilterReset: $options.locationFilterReset
                                }, null, 8, ["region", "area", "store", "onLocation", "onLocationFilterReset"])
                            ], 512), [
                                [_vShow, $data.showLocationFilter]
                            ]),
                            _withDirectives(_createElementVNode("span", null, [
                                _createVNode(_component_ProductFilter, {
                                    ref: "productFilter",
                                    parentdepartment: $data.newProductParentDepartment,
                                    department: $data.newProductDepartment,
                                    group: $data.newProductGroup,
                                    subgroup: $data.newProductSubGroup,
                                    variantplu: $data.newProductVariantPLU,
                                    onProduct: $options.selectedProduct,
                                    onProductFilterReset: $options.productFilterReset
                                }, null, 8, ["parentdepartment", "department", "group", "subgroup", "variantplu", "onProduct", "onProductFilterReset"])
                            ], 512), [
                                [_vShow, $data.showProductFilter]
                            ]),
                            _withDirectives(_createElementVNode("span", null, [
                                _createVNode(_component_WeekRangePicker, {
                                    ref: "weekFilter",
                                    onWeeks: $options.selectedWeekFilters
                                }, null, 8, ["onWeeks"])
                            ], 512), [
                                [_vShow, $data.showWeekFilter]
                            ]),
                            _withDirectives(_createElementVNode("span", null, [
                                _createVNode(_component_DateFilter, {
                                    ref: "dateFilter",
                                    onDateChanged: $options.selectedDate
                                }, null, 8, ["onDateChanged"])
                            ], 512), [
                                [_vShow, $data.showDateFilter]
                            ])
                        ])
                    ])
                ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.reportList, (report) => {
                return (_openBlock(), _createElementBlock("div", {
                    key: report.id
                }, [
                    _withDirectives((_openBlock(), _createElementBlock("div", {
                        key: report.id
                    }, [
                        _createVNode(_component_FilterManager, {
                            "is-active-report": report.id == $data.currentReportId,
                            "report-id": report.id,
                            bookmark: $data.bookmark,
                            "embedded-token": $data.embeddedToken,
                            location: $data.location,
                            product: $data.product,
                            weeks: $data.weeks,
                            date: $data.date,
                            "is-first-report": report.id == $data.firstReportId,
                            "page-data": $data.pageData,
                            "open-page-index": $data.openPageIndex,
                            "page-name": $data.pageName,
                            "first-page": $data.firstPage,
                            "reset-date-filter": $data.resetDateFilter,
                            "set-date-filter": $data.setDateFilter,
                            onRegionChanged: $options.regionChanged,
                            onAreaChanged: $options.areaChanged,
                            onStoreChanged: $options.storeChanged,
                            onParentdepartmentChanged: $options.parentDepartmentChanged,
                            onDepartmentChanged: $options.departmentChanged,
                            onGroupChanged: $options.groupChanged,
                            onSubgroupChanged: $options.subGroupChanged,
                            onVariantpluChanged: $options.variantPLUChanged,
                            onPageChanged: $options.pageChanged,
                            onUpdateRecentReportsCookie: $options.updateRecentReportsCookie
                        }, null, 8, ["is-active-report", "report-id", "bookmark", "embedded-token", "location", "product", "weeks", "date", "is-first-report", "page-data", "open-page-index", "page-name", "first-page", "reset-date-filter", "set-date-filter", "onRegionChanged", "onAreaChanged", "onStoreChanged", "onParentdepartmentChanged", "onDepartmentChanged", "onGroupChanged", "onSubgroupChanged", "onVariantpluChanged", "onPageChanged", "onUpdateRecentReportsCookie"])
                    ])), [
                        [_vShow, report.id == $data.currentReportId]
                    ])
                ]));
            }), 128))
        ], 512), [
            [_vShow, !$options.isHomePage]
        ])
    ], 64));
}
