import apiResponse from "@/models/base/apiResponse";
import { httpClient } from "@/helpers/httpClient";
export default class locationApi {
    resource = "/api/application/location";
    async getTradingStatuses() {
        const response = new apiResponse(async () => await httpClient.get(`${this.resource}/tradingstatuses`));
        await response.ready;
        return response;
    }
    async getRegions() {
        const response = new apiResponse(async () => await httpClient.get(`${this.resource}/regions`));
        await response.ready;
        return response;
    }
    async getAreas(regionId) {
        const response = new apiResponse(async () => await httpClient.get(`${this.resource}/region/${regionId}/areas`));
        await response.ready;
        return response;
    }
    async getStores(areaId) {
        const response = new apiResponse(async () => await httpClient.get(`${this.resource}/area/${areaId}/stores`));
        await response.ready;
        return response;
    }
}
