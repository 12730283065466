import apiResponse from "@/models/base/apiResponse";
import { httpClient } from "@/helpers/httpClient";
export default class productApi {
    resource = "/api/application/product";
    async getProductParentDepartments() {
        const response = new apiResponse(async () => await httpClient.get(`${this.resource}/parentdepartments/`));
        await response.ready;
        return response;
    }
    async getProductDepartments() {
        const response = new apiResponse(async () => await httpClient.get(`${this.resource}/departments/`));
        await response.ready;
        return response;
    }
    async getProductGroups() {
        const response = new apiResponse(async () => await httpClient.get(`${this.resource}/groups/`));
        await response.ready;
        return response;
    }
    async getProductSubGroups() {
        const response = new apiResponse(async () => await httpClient.get(`${this.resource}/subgroups/`));
        await response.ready;
        return response;
    }
    async getProductVariants() {
        const response = new apiResponse(async () => await httpClient.get(`${this.resource}/variantplus/`));
        await response.ready;
        return response;
    }
}
