import { httpClient } from "@/helpers/httpClient";
import User from "@/models/application/User";
export default class {
    resource = "/api/application";
    async getUser() {
        const response = await httpClient.get(`${this.resource}/user`);
        if (response.isAuthenticated) {
            return new User(response.name, response.userType, response.isAuthenticated, response.permissions, response.locationId, response.locationName, response.nexusUserId);
        }
        else
            return new User(undefined, undefined, false, undefined, 0, undefined, undefined);
    }
    async getUserDepartment(nexusUserId) {
        const response = await httpClient.get(`${this.resource}/${nexusUserId}/userdepartment`);
        return response;
    }
}
