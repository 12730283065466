import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue";
const _hoisted_1 = { class: "mt-5 ms-3 col-11 col-lg-10 col-xxl-9" };
const _hoisted_2 = _createElementVNode("h1", null, "Maintain Notifications", -1);
const _hoisted_3 = { key: 0 };
const _hoisted_4 = _createElementVNode("div", {
    class: "spinner-border spinner-border-sm",
    role: "status"
}, [
    _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
], -1);
const _hoisted_5 = [
    _hoisted_4
];
const _hoisted_6 = { key: 1 };
const _hoisted_7 = { key: 0 };
const _hoisted_8 = _createElementVNode("h5", null, "Active Notification", -1);
const _hoisted_9 = { class: "card shadow-sm" };
const _hoisted_10 = _createStaticVNode("<div class=\"card-header p-0\"><div class=\"row m-1 ps-1\"><div class=\"col-4 col-sm-6 p-0 fw-semibold\"> Title </div><div class=\"col-3 col-sm-2 p-0 text-center fw-semibold\"> From </div><div class=\"col-3 col-sm-2 p-0 text-center fw-semibold\"> To </div><div class=\"col-2 col-sm-2 p-0 text-center fw-semibold\"> End Now </div></div></div>", 1);
const _hoisted_11 = { class: "row m-1 ps-1" };
const _hoisted_12 = { class: "col-4 col-sm-6 p-0" };
const _hoisted_13 = { class: "col-3 col-sm-2 p-0 text-center" };
const _hoisted_14 = { class: "col-3 col-sm-2 p-0 text-center" };
const _hoisted_15 = { class: "col-2 p-0 text-center" };
const _hoisted_16 = ["xlink:href"];
const _hoisted_17 = { key: 1 };
const _hoisted_18 = _createElementVNode("h4", null, "No Active Notifications", -1);
const _hoisted_19 = _createElementVNode("button", { class: "btn btn-success" }, " Add Notification ", -1);
import { onMounted, ref } from "vue";
import { format } from "date-fns";
import Swal from "sweetalert2";
import notificationService from "@/services/application/notificationService";
import bootstrapIcons from "@/assets/images/bootstrapIcons";
import { useToasterStore } from "@/stores/toasterStore";
export default _defineComponent({
    __name: 'MaintainNotificationView',
    setup(__props) {
        const notificationSvc = new notificationService();
        const toasterStore = useToasterStore();
        const notification = ref();
        const activeNotification = ref(false);
        const loading = ref(true);
        onMounted(() => {
            getActiveNotification();
        });
        function getActiveNotification() {
            loading.value = true;
            notificationSvc.getMaintenanceNotification()
                .then((result) => {
                notification.value = result;
                if (result.id != undefined)
                    activeNotification.value = true;
                loading.value = false;
            });
        }
        function showConfirmPrompt(notification) {
            Swal.fire({
                title: "Are you sure you want to End this Notification?",
                showCancelButton: true,
                cancelButtonColor: "#A0202E",
                cancelButtonText: "No",
                confirmButtonText: "Yes",
                confirmButtonColor: "#43AC6A"
            }).then((result) => {
                if (result.isConfirmed) {
                    expireNotification(notification);
                }
            });
        }
        function expireNotification(notification) {
            notificationSvc.expireNotification(notification.id)
                .then(() => {
                toasterStore.addSlice({ header: "Notification ended", body: `Notification successfully ended: ${notification.title}` });
                activeNotification.value = false;
            });
        }
        return (_ctx, _cache) => {
            const _component_RouterLink = _resolveComponent("RouterLink");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _hoisted_2,
                (loading.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _hoisted_5))
                    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        (activeNotification.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _hoisted_8,
                                _createElementVNode("div", _hoisted_9, [
                                    _hoisted_10,
                                    _createElementVNode("div", _hoisted_11, [
                                        _createElementVNode("div", _hoisted_12, _toDisplayString(notification.value?.title), 1),
                                        _createElementVNode("small", _hoisted_13, _toDisplayString(_unref(format)(new Date(notification.value.fromDate), "dd/MM/yyyy HH:mm:ss")), 1),
                                        _createElementVNode("small", _hoisted_14, _toDisplayString(_unref(format)(new Date(notification.value.expiryDate), "dd/MM/yyyy HH:mm:ss")), 1),
                                        _createElementVNode("div", _hoisted_15, [
                                            (_openBlock(), _createElementBlock("svg", {
                                                "data-testid": "expireNotificationIcon",
                                                class: "bi",
                                                width: "22",
                                                height: "22",
                                                fill: "#A0202E",
                                                onClick: _cache[0] || (_cache[0] = ($event) => (showConfirmPrompt(notification.value)))
                                            }, [
                                                _createElementVNode("use", {
                                                    "xlink:href": `${_unref(bootstrapIcons)}#clock-history`
                                                }, null, 8, _hoisted_16)
                                            ]))
                                        ])
                                    ])
                                ])
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                _hoisted_18,
                                _createVNode(_component_RouterLink, { to: { name: 'Add Notification' } }, {
                                    default: _withCtx(() => [
                                        _hoisted_19
                                    ]),
                                    _: 1
                                })
                            ]))
                    ]))
            ]));
        };
    }
});
