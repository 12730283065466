export default class apiResponse {
    response;
    errorResponse;
    get success() {
        return this.errorResponse === undefined;
    }
    ready;
    isProblem(error) {
        return error.status !== undefined;
    }
    constructor(requestFunc) {
        this.ready = new Promise((resolve) => {
            requestFunc()
                .then(response => {
                this.response = response;
                resolve(undefined);
            })
                .catch(error => {
                if (this.isProblem(error)) {
                    this.errorResponse = error;
                }
                else {
                    this.errorResponse = {
                        detail: "An unexpected error occurred",
                        status: 500
                    };
                }
            });
        });
    }
}
