import { defineStore } from "pinia";
export const useApplicationStore = defineStore({
    id: "application",
    state: () => ({
        breadcrumbPath: undefined,
        menuData: undefined,
        userDepartment: undefined
    }),
    getters: {
        applicationMenu: (state) => state.menuData,
        breadcrumbs: (state) => state.breadcrumbPath,
        userDepartmentName: (state) => state.userDepartment?.departmentName
    }
});
