import User from "@/models/application/User";
import { defineStore } from "pinia";
import { minTime } from "date-fns";
export const useAuthStore = defineStore({
    id: "auth",
    state: () => ({
        lastAuthenticatedDate: new Date(minTime),
        userDetails: new User(),
    }),
    getters: {
        lastAuthenticated: (state) => state.lastAuthenticatedDate,
        user: (state) => state.userDetails,
    }
});
