import { httpClient } from "@/helpers/httpClient";
import apiResponse from "@/models/base/apiResponse";
export default class {
    resource = "/api/application/profile";
    async getProfile() {
        const profileResponse = new apiResponse(async () => await httpClient.get(`${this.resource}`));
        await profileResponse.ready;
        return profileResponse;
    }
}
