import apiResponse from "@/models/base/apiResponse";
import { httpClient } from "@/helpers/httpClient";
export default class pageChangeApi {
    resource = "/api/application/pagechange";
    async pageChange(currentPageName, newPageName, route) {
        const requestData = { currentPageName: currentPageName, newPageName: newPageName, route: route };
        const response = new apiResponse(async () => await httpClient.post(this.resource, requestData));
        await response.ready;
        return response;
    }
}
