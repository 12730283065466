import apiResponse from "@/models/base/apiResponse";
import { httpClient } from "@/helpers/httpClient";
export default class reportPageApi {
    resource = "/api/application/reportpages";
    async getReportPages() {
        const response = new apiResponse(async () => await httpClient.get(this.resource));
        await response.ready;
        return response;
    }
}
