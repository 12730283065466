import { apiFactory } from "@/api/factories/apiFactory";
class calendarService {
    _calendarApi = apiFactory.useCalendarApi();
    async getCalendar() {
        const result = await this._calendarApi.getCalendar();
        if (result.response && result.success) {
            return result.response;
        }
        return [];
    }
}
export default calendarService;
