export const httpClient = {
    get,
    post,
    put,
    delete: _delete,
    patch
};
import { formatISO } from "date-fns";
Date.prototype.toJSON = function () {
    return formatISO(this, { representation: "complete" });
};
async function get(url, queryParams, additionalHeaders = {}) {
    if (queryParams) {
        const params = new URLSearchParams(queryParams).toString();
        url = url.concat(url.includes("?") ? "&" : "?", params);
    }
    const headers = new Headers();
    headers.append("X-Requested-With", "XMLHttpRequest");
    headers.append("Content-Type", "application/json");
    for (const header in additionalHeaders) {
        headers.append(header, additionalHeaders[header]);
    }
    const requestOptions = {
        method: "GET",
        cache: "no-store",
        headers,
    };
    return await fetchWithErrorHandling(url, requestOptions);
}
async function post(url, body) {
    const headers = new Headers();
    headers.append("X-Requested-With", "XMLHttpRequest");
    headers.append("Content-Type", "application/json");
    const requestOptions = {
        method: "POST",
        headers,
        body: JSON.stringify(body)
    };
    return handleResponse(await fetch(url, requestOptions));
}
async function put(url, resourceId, body) {
    if (resourceId != undefined && !url.includes(`/${resourceId}`)) {
        url += !url.endsWith("/") ? `/${resourceId}` : resourceId;
    }
    const headers = new Headers();
    headers.append("X-Requested-With", "XMLHttpRequest");
    headers.append("Content-Type", "application/json");
    const requestOptions = {
        method: "PUT",
        headers,
        body: JSON.stringify(body),
    };
    return handleResponse(await fetch(url, requestOptions));
}
async function patch(url, resourceId, body) {
    if (resourceId != undefined && !url.includes(`/${resourceId}`)) {
        url += !url.endsWith("/") ? `/${resourceId}` : resourceId;
    }
    const headers = new Headers();
    headers.append("X-Requested-With", "XMLHttpRequest");
    headers.append("Content-Type", "application/json");
    const requestOptions = {
        method: "PATCH",
        headers,
        body: JSON.stringify(body),
    };
    return handleResponse(await fetch(url, requestOptions));
}
async function _delete(url, resourceId, body) {
    if (resourceId != undefined && !url.includes(`/${resourceId}`)) {
        url += !url.endsWith("/") ? `/${resourceId}` : resourceId;
    }
    const headers = new Headers();
    headers.append("X-Requested-With", "XMLHttpRequest");
    headers.append("Content-Type", "application/json");
    const requestOptions = {
        method: "DELETE",
        headers,
        body: JSON.stringify(body)
    };
    return handleResponse(await fetch(url, requestOptions));
}
async function fetchWithErrorHandling(url, requestOptions) {
    try {
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
        }
        return handleResponse(response);
    }
    catch (error) {
        return Promise.reject(error);
    }
}
async function handleResponse(response) {
    let text = await response.text();
    if (!text && response.ok) {
        text = "{}";
    }
    let data;
    try {
        data = text && JSON.parse(text);
    }
    catch (e) {
        throw "Invalid JSON string. Please ensure controller returns valid JSON string not Raw text";
    }
    if (!response.ok) {
        const error = data || { message: response.statusText, status: response.status };
        return Promise.reject(error);
    }
    return data;
}
