import { createApp } from "vue";
import { createPinia } from "pinia";
import applicationService from "@/services/application/applicationService";
import App from "@/App.vue";
import router from "@/router";
import "bootstrap";
import "@/assets/scss/site.scss";
const app = createApp(App)
    .use(createPinia())
    .use(router);
app.mount("#app");
const appService = new applicationService();
appService.initialiseApplication();
