export default {
    name: "DateFilter",
    data() {
        return {
            dateSelected: String()
        };
    },
    async mounted() {
        const date = new Date();
        this.emitDateWithoutTimezone(date);
    },
    methods: {
        onChangeOfDate(dateSelection) {
            this.emitDateWithoutTimezone(dateSelection.target.value);
        },
        emitDateWithoutTimezone(dateTime) {
            this.dateSelected = dateTime;
            if (this.dateSelected === "") {
                this.$emit("dateChanged", "clearDate");
            }
            else {
                const date = new Date(this.dateSelected);
                const dateLocalTime = new Date(date).toLocaleTimeString("it-IT");
                const hours = +dateLocalTime.substring(0, 2);
                if (!Number.isNaN(hours)) {
                    date.setHours(date.getHours() - hours);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    date.setMilliseconds(0);
                }
                const dateString = new Date(date).toISOString();
                this.$emit("dateChanged", dateString);
            }
        },
        setTodayDate() {
            return new Date().toISOString().substring(0, 10);
        }
    }
};
