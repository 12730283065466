import userApi from "@/api/application/userApi";
import applicationApi from "@/api/application/profileApi";
import healthCheckApi from "@/api/application/healthCheckApi";
import embeddedTokenApi from "@/api/application/embeddedTokenApi";
import workspaceApi from "@/api/powerbi/workspaceApi";
import reportApi from "@/api/powerbi/reportApi";
import locationApi from "@/api/application/locationApi";
import productApi from "@/api/application/productApi";
import calendarApi from "@/api/application/calendarApi";
import notificationApi from "@/api/application/notificationApi";
import routeReportApi from "@/api/application/routeReportApi";
import pageChangeApi from "@/api/application/pageChangeApi";
import logFilterApi from "@/api/application/logFilterApi";
import reportPageApi from "@/api/application/reportPageApi";
import recommendedReportApi from "@/api/application/recommendedReportApi";
const apis = {
    user: new userApi(),
    profile: new applicationApi(),
    healthCheck: new healthCheckApi(),
    embeddedToken: new embeddedTokenApi(),
    workspace: new workspaceApi(),
    report: new reportApi(),
    location: new locationApi(),
    product: new productApi(),
    calendar: new calendarApi(),
    notification: new notificationApi(),
    routeReport: new routeReportApi(),
    pageChange: new pageChangeApi(),
    logFilter: new logFilterApi(),
    reportPage: new reportPageApi(),
    recommendedReport: new recommendedReportApi()
};
export const apiFactory = {
    useUserApi: () => apis.user,
    useHealthCheckApi: () => apis.healthCheck,
    useProfileApi: () => apis.profile,
    useEmbeddedTokenApi: () => apis.embeddedToken,
    useWorkspaceApi: () => apis.workspace,
    useReportApi: () => apis.report,
    useLocationApi: () => apis.location,
    useProductApi: () => apis.product,
    useCalendarApi: () => apis.calendar,
    useNotificationApi: () => apis.notification,
    useRouteReportApi: () => apis.routeReport,
    usePageChangeApi: () => apis.pageChange,
    useLogFilterApi: () => apis.logFilter,
    useReportPageApi: () => apis.reportPage,
    useRecommendedReportApi: () => apis.recommendedReport
};
