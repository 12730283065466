var Policy;
(function (Policy) {
    Policy["BASIC_ACCESS"] = "VWPT_BasicAccess";
    Policy["SYS_ADMIN"] = "VWPT_SysAdmin";
    Policy["REGIONAL_MANAGER"] = "VWPT_RegionalManager";
    Policy["AREA_MANAGER"] = "VWPT_AreaManager";
    Policy["STORE_MANAGER"] = "VWPT_StoreManager";
    Policy["DEESIDE_USER"] = "VWPT_DeesideUser";
    Policy["HR_COLLEAGUE"] = "VWPT_HRColleague";
    Policy["WFM_COLLEAGUE"] = "VWPT_WFMColleague";
    Policy["LOSS_PREVENTION"] = "VWPT_LossPrevention";
})(Policy || (Policy = {}));
export { Policy };
