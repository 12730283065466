export default {
    name: "loadingOverlay",
    props: {
        isOverlay: { type: Boolean, default: false },
        isTransparent: { type: Boolean, default: false }
    },
    data() {
        return {
            isLoading: false,
            loadingBarClass: "progress-bar-fill-new"
        };
    },
    watch: {
        isOverlay: {
            handler: async function (newValue, oldValue) {
                if (newValue == undefined)
                    return;
                if (newValue == true || oldValue == false) {
                    this.loadingBarClass = "";
                    this.isLoading = true;
                }
                if (newValue == false || oldValue == true) {
                    await this.delay(300);
                    this.isLoading = false;
                    this.loadingBarClass = "progress-bar-fill-new";
                }
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
    }
};
