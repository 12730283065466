import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "container py-4"
};
const _hoisted_2 = _createElementVNode("div", { class: "p-5 mb-4 bg-light rounded-3 text-center" }, [
    _createElementVNode("h1", { class: "display-5 fw-bold" }, " Signing In "),
    _createElementVNode("p", { class: "col-md fs-4" }, " Redirecting to sign-in page. "),
    _createElementVNode("div", { class: "progress" }, [
        _createElementVNode("div", {
            class: "progress-bar progress-bar-striped progress-bar-animated",
            role: "progressbar",
            "aria-valuenow": "100",
            "aria-valuemin": "0",
            "aria-valuemax": "100",
            style: { "width": "100%" }
        }, "   ")
    ])
], -1);
const _hoisted_3 = [
    _hoisted_2
];
import AuthService from "@/services/application/authService";
import { useAuthStore } from "@/stores/authStore";
import { onMounted } from "vue";
import { useRouter } from "vue-router";
export default _defineComponent({
    __name: 'LandingPage',
    setup(__props) {
        const router = useRouter();
        const authStore = useAuthStore();
        const authService = new AuthService();
        if (!authStore.user.isAuthenticated) {
            authService.getUser();
        }
        onMounted(() => {
            router.push({ name: "Home" });
        });
        return (_ctx, _cache) => {
            return (!_unref(authStore).user.isAuthenticated)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, _hoisted_3))
                : _createCommentVNode("", true);
        };
    }
});
