export default class User {
    name;
    userType;
    isAuthenticated;
    permissions;
    locationId;
    locationName;
    nexusUserId;
    constructor(name = "", userType = "", isAuthenticated = false, permissions = [], locationId = 0, locationName = "", nexusUserId = "") {
        this.name = name;
        this.userType = userType;
        this.isAuthenticated = isAuthenticated;
        this.permissions = permissions;
        this.locationId = locationId;
        this.locationName = locationName;
        this.nexusUserId = nexusUserId;
    }
    meetsPolicies(policies) {
        return (policies.length == 0 || policies.some((p) => this.permissions.includes(p)));
    }
    meetsPolicy(policy) {
        return this.meetsPolicies([policy]);
    }
}
