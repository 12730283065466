import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    id: "notificationForm",
    class: "mt-5 ms-3 col-11 col-lg-10 col-xxl-9"
};
const _hoisted_2 = _createElementVNode("h1", null, "Add Notification", -1);
const _hoisted_3 = { class: "row my-3 p-1" };
const _hoisted_4 = _createElementVNode("label", {
    for: "inputTitle",
    class: "col-2 col-form-label-lg"
}, "Title", -1);
const _hoisted_5 = { class: "col-5 my-auto" };
const _hoisted_6 = { class: "row my-3 p-1" };
const _hoisted_7 = _createElementVNode("label", {
    for: "inputBody",
    class: "col-2 col-form-label-lg"
}, "Body", -1);
const _hoisted_8 = { class: "col-10 my-auto" };
const _hoisted_9 = { class: "row my-3 p-1" };
const _hoisted_10 = _createElementVNode("label", {
    for: "inputFromDate",
    class: "col-2 col-form-label-lg"
}, "From Date", -1);
const _hoisted_11 = { class: "col-6 col-md-4 col-xl-3 my-auto" };
const _hoisted_12 = { class: "form-check" };
const _hoisted_13 = _createElementVNode("label", {
    class: "form-check-label",
    for: "fromCheckbox"
}, "Display Notification Immediately", -1);
const _hoisted_14 = { class: "row my-3 p-1" };
const _hoisted_15 = _createElementVNode("label", {
    for: "inputExpiryDate",
    class: "col-2 col-form-label-lg"
}, "Expiry Date", -1);
const _hoisted_16 = { class: "col-6 col-md-4 col-xl-3 my-auto" };
const _hoisted_17 = { class: "d-flex" };
const _hoisted_18 = ["disabled"];
const _hoisted_19 = _createElementVNode("button", { class: "btn btn-primary" }, " Cancel ", -1);
import { onMounted, ref } from "vue";
import router from "@/router";
import notificationService from "@/services/application/notificationService";
import { useToasterStore } from "@/stores/toasterStore";
export default _defineComponent({
    __name: 'AddNotificationView',
    setup(__props) {
        const notificationSvc = new notificationService();
        const toasterStore = useToasterStore();
        let submitButtonDisabled = ref(true);
        let validTitle = ref(false);
        let validBody = ref(false);
        let validFromDate = ref(true);
        let validExpiryDate = ref(false);
        let displayFromDate = ref(false);
        onMounted(() => {
            let form = document.getElementById("notificationForm");
            form?.addEventListener("submit", event => {
                event.preventDefault();
                event.stopPropagation();
                let notificationTitle = document.getElementById("inputTitle").value.trim();
                let notificationBody = document.getElementById("inputBody").value.trim();
                let notificationFromDate = document.getElementById("inputFromDate")?.value;
                let notificationExpiryDate = document.getElementById("inputExpiryDate").value;
                submitForm(notificationTitle, notificationBody, notificationFromDate, notificationExpiryDate);
            });
        });
        function onChangeOfTitle(title) {
            const inputTitle = document.getElementById("inputTitle");
            if (title.target.value.trim().length <= 0) {
                validTitle.value = false;
                updateInputClass(inputTitle, "border-success", "border-danger");
            }
            else {
                validTitle.value = true;
                updateInputClass(inputTitle, "border-danger", "border-success");
            }
            validateForm();
        }
        function onChangeOfBody(body) {
            const inputBody = document.getElementById("inputBody");
            if (body.target.value.trim().length <= 0) {
                validBody.value = false;
                updateInputClass(inputBody, "border-success", "border-danger");
            }
            else {
                validBody.value = true;
                updateInputClass(inputBody, "border-danger", "border-success");
            }
            validateForm();
        }
        function onChangeOfFromDate() {
            let inputFromDate = document.getElementById("inputFromDate");
            const inputExpiryDate = document.getElementById("inputExpiryDate");
            const notificationExpiryDate = document.getElementById("inputExpiryDate").value;
            const notificationFromDate = document.getElementById("inputFromDate")?.value;
            const isFromDateValid = notificationFromDate >= new Date().toISOString().substring(0, 16) && notificationFromDate !== "" || !displayFromDate.value;
            const isExpiryDateValid = notificationExpiryDate >= new Date().toISOString().substring(0, 16) && notificationExpiryDate !== "" && notificationFromDate < notificationExpiryDate;
            validFromDate.value = isFromDateValid;
            updateInputClass(inputFromDate, isFromDateValid ? "border-danger" : "border-success", isFromDateValid ? "border-success" : "border-danger");
            validExpiryDate.value = isExpiryDateValid;
            updateInputClass(inputExpiryDate, isExpiryDateValid ? "border-danger" : "border-success", isExpiryDateValid ? "border-success" : "border-danger");
            validateForm();
        }
        function onChangeOfExpiryDate() {
            const inputExpiryDate = document.getElementById("inputExpiryDate");
            const inputFromDate = document.getElementById("inputFromDate");
            const notificationExpiryDate = document.getElementById("inputExpiryDate").value;
            const notificationFromDate = document.getElementById("inputFromDate")?.value;
            const isExpiryDateValid = notificationExpiryDate >= new Date().toISOString().substring(0, 16) && notificationExpiryDate !== "" && notificationFromDate < notificationExpiryDate;
            const isFromDateValid = notificationFromDate >= new Date().toISOString().substring(0, 16) && notificationFromDate !== "" || !displayFromDate.value;
            validExpiryDate.value = isExpiryDateValid;
            updateInputClass(inputExpiryDate, isExpiryDateValid ? "border-danger" : "border-success", isExpiryDateValid ? "border-success" : "border-danger");
            validFromDate.value = isFromDateValid;
            updateInputClass(inputFromDate, isFromDateValid ? "border-danger" : "border-success", isFromDateValid ? "border-success" : "border-danger");
            validateForm();
        }
        function toggleFromDate() {
            displayFromDate.value = !displayFromDate.value;
            const inputFromDate = document.getElementById("inputFromDate");
            if (inputFromDate) {
                inputFromDate.required = displayFromDate.value;
            }
            onChangeOfFromDate();
            validateForm();
        }
        function updateInputClass(element, removeClass, addClass) {
            if (element) {
                element.classList.remove(removeClass);
                element.classList.add(addClass);
            }
        }
        function validateForm() {
            const isFormValid = validTitle.value && validBody.value && validFromDate.value && validExpiryDate.value;
            submitButtonDisabled.value = !isFormValid;
        }
        async function submitForm(title, body, fromDate, expiryDate) {
            try {
                const notification = { title, body, createdDate: new Date(), fromDate: displayFromDate.value ? fromDate : new Date(), expiryDate };
                const response = await notificationSvc.addNotification(notification);
                if (response) {
                    toasterStore.addSlice({ header: "Notification added", body: `Notification successfully added: ${title}` });
                    router.push("/admin/notification");
                }
            }
            catch (error) {
                console.log("Logging error:", error);
            }
        }
        function expandPicker(elementId) {
            const picker = document.getElementById(elementId);
            picker.showPicker();
        }
        return (_ctx, _cache) => {
            const _component_RouterLink = _resolveComponent("RouterLink");
            return (_openBlock(), _createElementBlock("form", _hoisted_1, [
                _hoisted_2,
                _createElementVNode("div", _hoisted_3, [
                    _hoisted_4,
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("input", {
                            id: "inputTitle",
                            type: "text",
                            class: "form-control",
                            placeholder: "Enter Notification Title",
                            required: "",
                            onChange: onChangeOfTitle
                        }, null, 32)
                    ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                    _hoisted_7,
                    _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("textarea", {
                            id: "inputBody",
                            class: "form-control",
                            placeholder: "Enter Notification Body",
                            required: "",
                            onChange: onChangeOfBody
                        }, null, 32)
                    ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                    _hoisted_10,
                    _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("input", {
                                class: "form-check-input",
                                type: "checkbox",
                                checked: "",
                                id: "fromCheckbox",
                                onChange: toggleFromDate
                            }, null, 32),
                            _hoisted_13
                        ]),
                        _withDirectives(_createElementVNode("input", {
                            id: "inputFromDate",
                            type: "datetime-local",
                            class: "form-control",
                            onChange: onChangeOfFromDate,
                            onClick: _cache[0] || (_cache[0] = ($event) => (_unref(displayFromDate) ? expandPicker('inputFromDate') : null))
                        }, null, 544), [
                            [_vShow, _unref(displayFromDate)]
                        ])
                    ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                    _hoisted_15,
                    _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("input", {
                            id: "inputExpiryDate",
                            type: "datetime-local",
                            class: "form-control",
                            required: "",
                            onChange: onChangeOfExpiryDate,
                            onClick: _cache[1] || (_cache[1] = ($event) => (expandPicker('inputExpiryDate')))
                        }, null, 32)
                    ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("button", {
                        class: "btn btn-success",
                        disabled: _unref(submitButtonDisabled)
                    }, " Add Notification ", 8, _hoisted_18),
                    _createVNode(_component_RouterLink, {
                        to: { name: 'Maintain Notifications' },
                        class: "ms-auto"
                    }, {
                        default: _withCtx(() => [
                            _hoisted_19
                        ]),
                        _: 1
                    })
                ])
            ]));
        };
    }
});
