import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = { class: "toast-container position-fixed bottom-0 end-0 p-3 m-3" };
const _hoisted_2 = ["id"];
const _hoisted_3 = { class: "toast-header text-bg-primary ps-2" };
const _hoisted_4 = {
    class: "bi me-2",
    width: "22",
    height: "22",
    fill: "white"
};
const _hoisted_5 = ["xlink:href"];
const _hoisted_6 = { class: "me-auto" };
const _hoisted_7 = ["onClick"];
const _hoisted_8 = { class: "toast-body" };
import { Toast } from "bootstrap";
import bootstrapIcons from "@/assets/images/bootstrapIcons";
import { useToasterStore } from "@/stores/toasterStore";
import { format } from "date-fns";
export default _defineComponent({
    __name: 'ToastComponent',
    setup(__props) {
        const toaster = useToasterStore();
        function toastSlice(slot, slice) {
            if (slot != null && slot instanceof Element) {
                const toast = new Toast(slot, { autohide: slice.disableAutoHide ? false : true });
                toast.show();
                slot.addEventListener("hidden.bs.toast", () => {
                    toaster.removeToast(slice);
                });
            }
        }
        function manualDismiss(toast) {
            if (toast.dismissFunction) {
                toast.dismissFunction();
            }
            toaster.removeToast(toast);
        }
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(toaster).toastRack, (slice) => {
                    return (_openBlock(), _createElementBlock("div", {
                        id: slice.time?.getTime().toString(),
                        key: slice.time?.getTime(),
                        ref_for: true,
                        ref: slot => toastSlice(slot, slice),
                        class: "toast border-0",
                        role: "alert",
                        "aria-live": "assertive",
                        "aria-atomic": "true"
                    }, [
                        _createElementVNode("div", _hoisted_3, [
                            (_openBlock(), _createElementBlock("svg", _hoisted_4, [
                                _createElementVNode("use", {
                                    "xlink:href": `${_unref(bootstrapIcons)}#check-circle`
                                }, null, 8, _hoisted_5)
                            ])),
                            _createElementVNode("strong", _hoisted_6, _toDisplayString(slice.header), 1),
                            _createElementVNode("small", null, _toDisplayString(slice.time ? _unref(format)(slice.time, "hh:mm aaa") : ""), 1),
                            _createElementVNode("button", {
                                type: "button",
                                class: "btn-close btn-close-white ms-1",
                                "data-bs-dismiss": "toast",
                                "aria-label": "Close",
                                onClick: ($event) => (manualDismiss(slice))
                            }, null, 8, _hoisted_7)
                        ]),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(slice.body), 1)
                    ], 8, _hoisted_2));
                }), 128))
            ]));
        };
    }
});
