import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { class: "container-fluid mt-5" };
const _hoisted_2 = { class: "card border col-9" };
const _hoisted_3 = _createElementVNode("div", { class: "card-header px-1" }, [
    _createElementVNode("h5", { class: "fw-semibold mx-1 my-0" }, " Publish Power BI Report ")
], -1);
const _hoisted_4 = { class: "card-body p-2" };
const _hoisted_5 = { class: "row small" };
const _hoisted_6 = _createElementVNode("div", { class: "col-3 pe-0 fw-semibold" }, " Workspace: ", -1);
const _hoisted_7 = { class: "col-2 p-0 m-0" };
const _hoisted_8 = { class: "row my-1 small" };
const _hoisted_9 = _createElementVNode("div", { class: "col-3 pe-0 fw-semibold" }, " Workspace Name: ", -1);
const _hoisted_10 = { class: "col-9 ps-1 card-text" };
const _hoisted_11 = _createElementVNode("hr", { class: "my-2 mx-1" }, null, -1);
const _hoisted_12 = { class: "row small" };
const _hoisted_13 = _createElementVNode("div", { class: "col-3 pe-0 fw-semibold" }, " Report: ", -1);
const _hoisted_14 = { class: "col-2 p-0 m-0" };
const _hoisted_15 = { class: "row my-1 small" };
const _hoisted_16 = _createElementVNode("div", { class: "col-3 pe-0 fw-semibold" }, " Report Name: ", -1);
const _hoisted_17 = { class: "col-9 ps-1 card-text" };
const _hoisted_18 = ["xlink:href"];
const _hoisted_19 = {
    key: 0,
    class: "row my-1 small"
};
const _hoisted_20 = { class: "input-group ps-0" };
const _hoisted_21 = ["value"];
const _hoisted_22 = _createElementVNode("hr", { class: "my-2 mx-1" }, null, -1);
const _hoisted_23 = { class: "row small" };
const _hoisted_24 = _createElementVNode("div", { class: "col-3 pe-0 fw-semibold" }, " Category: ", -1);
const _hoisted_25 = { class: "col-2 p-0 m-0" };
const _hoisted_26 = { class: "card-footer p-2" };
const _hoisted_27 = ["disabled"];
import bootstrapIcons from "@/assets/images/bootstrapIcons";
import { ref } from "vue";
import powerBiService from "@/services/powerbi/powerBiService";
import locationService from "@/services/application/locationService";
import Dropdown from "@/components/root/dropdown.vue";
import categories from "@/assets/temp/reportCategoriesList.json";
export default _defineComponent({
    __name: 'PublishReportView',
    setup(__props) {
        const powerBiSvc = new powerBiService();
        const locationSvc = new locationService();
        const stores = ref([]);
        const workspaces = ref([]);
        const selectedWorkspace = ref();
        const workspaceSelected = ref(false);
        const reports = ref([]);
        const selectedReport = ref();
        const reportSelected = ref(false);
        const editReport = ref(false);
        const selectedCategory = ref();
        const categorySelected = ref(false);
        function getStores() {
            locationSvc.getStores()
                .then((result) => {
                stores.value = result;
                s;
            });
        }
        getStores();
        getWorkspaces();
        function getWorkspaces() {
            powerBiSvc.getWorkspaces()
                .then((result) => {
                workspaces.value = result.map(workspace => {
                    return {
                        id: workspace.id,
                        name: workspace.name
                    };
                })
                    .sort((a, b) => (a.name > b.name ? 1 : -1));
            });
        }
        function getReports(selection) {
            selectedWorkspace.value = selection;
            workspaceSelected.value = true;
            selectedReport.value = undefined;
            reportSelected.value = false;
            editReport.value = false;
            powerBiSvc.getReports(selection.id)
                .then((result) => {
                reports.value = result.map(report => {
                    return {
                        id: report.id,
                        datasetWorkspaceId: report.datasetWorkspaceId,
                        name: report.name
                    };
                })
                    .sort((a, b) => (a.name > b.name ? 1 : -1));
            });
        }
        function setReport(selection) {
            selectedReport.value = selection;
            reportSelected.value = true;
        }
        function setCategory(selection) {
            selectedCategory.value = selection;
            categorySelected.value = true;
        }
        function editReportName() {
            editReport.value = !editReport.value;
        }
        function allowSubmit() {
            if (workspaceSelected.value && reportSelected.value && categorySelected.value) {
                return true;
            }
            else {
                return false;
            }
        }
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _hoisted_3,
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                            _hoisted_6,
                            _createElementVNode("div", _hoisted_7, [
                                _createVNode(Dropdown, {
                                    options: workspaces.value,
                                    "default-item": 'Select workspace',
                                    disabled: false,
                                    "input-value": 'Select workspace',
                                    "max-item": "20",
                                    name: "workspace",
                                    onSelected: getReports
                                }, null, 8, ["options"])
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                            _hoisted_9,
                            _createElementVNode("div", _hoisted_10, _toDisplayString(selectedWorkspace.value?.name), 1)
                        ]),
                        _hoisted_11,
                        _createElementVNode("div", _hoisted_12, [
                            _hoisted_13,
                            _createElementVNode("div", _hoisted_14, [
                                _createVNode(Dropdown, {
                                    options: reports.value,
                                    "default-item": 'Select report',
                                    disabled: !workspaceSelected.value,
                                    "input-value": 'Select report',
                                    "max-item": "100",
                                    name: "report",
                                    onSelected: setReport
                                }, null, 8, ["options", "disabled"])
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_15, [
                            _hoisted_16,
                            _createElementVNode("div", _hoisted_17, [
                                _createTextVNode(_toDisplayString(selectedReport.value?.name) + " ", 1),
                                (reportSelected.value)
                                    ? (_openBlock(), _createElementBlock("svg", {
                                        key: 0,
                                        class: "bi mx-2",
                                        width: "16",
                                        height: "20",
                                        fill: "secondary",
                                        title: "Edit",
                                        onClick: _cache[0] || (_cache[0] = ($event) => (editReportName()))
                                    }, [
                                        _createElementVNode("use", {
                                            "xlink:href": `${_unref(bootstrapIcons)}#pencil`
                                        }, null, 8, _hoisted_18)
                                    ]))
                                    : _createCommentVNode("", true)
                            ])
                        ]),
                        (editReport.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                _createElementVNode("div", _hoisted_20, [
                                    _createElementVNode("input", {
                                        type: "text",
                                        class: "form-control-sm col-8 offset-3 px-1 py-0 border-1",
                                        value: selectedReport.value?.name
                                    }, null, 8, _hoisted_21)
                                ])
                            ]))
                            : _createCommentVNode("", true),
                        _hoisted_22,
                        _createElementVNode("div", _hoisted_23, [
                            _hoisted_24,
                            _createElementVNode("div", _hoisted_25, [
                                _createVNode(Dropdown, {
                                    options: _unref(categories),
                                    "default-item": 'Select category',
                                    "input-value": 'Select category',
                                    name: "category",
                                    onSelected: setCategory
                                }, null, 8, ["options"])
                            ])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("button", {
                            type: "submit",
                            class: "btn btn-primary",
                            disabled: !allowSubmit()
                        }, " Publish Report ", 8, _hoisted_27)
                    ])
                ])
            ]));
        };
    }
});
