import { apiFactory } from "@/api/factories/apiFactory";
import { useAuthStore } from "@/stores/authStore";
class AuthService {
    _userApi = apiFactory.useUserApi();
    _authStore = useAuthStore();
    login(returnUrl) {
        window.location.href = `/application/auth/login?origin=${returnUrl}`;
    }
    logout() {
        window.location.href = "/application/auth/logout";
        this._authStore.$reset();
    }
    async getUser() {
        const user = await this._userApi.getUser();
        if (user.isAuthenticated) {
            this._authStore.userDetails = user;
            this._authStore.lastAuthenticatedDate = new Date();
        }
        else {
            this._authStore.$reset();
        }
    }
}
export default AuthService;
