import { defineStore } from "pinia";
import { computed, ref } from "vue";
export const useToasterStore = defineStore("toaster", () => {
    const _toaster = ref(new Array());
    const _sessionToast = ref();
    const toastRack = computed(() => _toaster.value);
    const sessionToast = computed(() => _sessionToast.value);
    function addSlice(slice) {
        if (slice.time == undefined) {
            slice.time = new Date();
        }
        _toaster.value.push(slice);
    }
    function removeToast(toast) {
        _toaster.value = _toaster.value.filter(slice => slice.time?.valueOf() != toast.time?.valueOf());
    }
    return {
        _toaster,
        _sessionToast,
        toastRack,
        sessionToast,
        addSlice,
        removeToast
    };
});
