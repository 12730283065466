import { apiFactory } from "@/api/factories/apiFactory";
class healthCheck {
    _healthCheckApi = apiFactory.useHealthCheckApi();
    async checkConnection() {
        let result = false;
        try {
            result = await this._healthCheckApi.getHealthCheck();
        }
        catch {
            return false;
        }
        return result;
    }
}
export default healthCheck;
