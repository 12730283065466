import Dropdown from "@/components/root/dropdown.vue";
import calendarService from "@/services/application/calendarService";
const calendarSvc = new calendarService();
export default {
    name: "WeekRangePicker",
    components: {
        Dropdown
    },
    emits: ["weeks"],
    data() {
        return {
            calendarData: {},
            dateTypes: [{ id: "TW", name: "Current Week" }, { id: "LW", name: "Last Week" }, { id: "YTD", name: "Year To Date" }, { id: "CW", name: "Custom Week" }, { id: "CWR", name: "Custom Range" }],
            dateTypeSelected: { id: "", name: "" },
            showDateTypeSelection: false,
            weeksList: [],
            weekFrom: 0,
            weekTo: 0,
            dropdownLabel: ""
        };
    },
    computed: {
        startDateLabel() { return (this.dateTypeSelected.id == "CW") ? "Week" : "From"; },
    },
    watch: {
        async dateTypeSelected(dateTypeSelected) {
            await this.until(_ => this.weeksList.length > 0);
            let isCurrentWeek = false;
            if (dateTypeSelected.id == "TW") {
                isCurrentWeek = true;
                this.weekFrom = this.getWeekFromRelativeNo(0);
                this.weekTo = this.weekFrom;
                this.dropdownLabel = dateTypeSelected.name + " " + this.weekFrom;
            }
            else if (dateTypeSelected.id == "LW") {
                this.weekFrom = this.getWeekFromRelativeNo(-1);
                this.weekTo = this.weekFrom;
                this.dropdownLabel = dateTypeSelected.name + " " + this.weekFrom;
            }
            else if (dateTypeSelected.id == "CW") {
                this.weekTo = this.weekFrom;
                this.dropdownLabel = dateTypeSelected.name;
            }
            else if (dateTypeSelected.id == "YTD") {
                const retailYear = this.getWeekFromRelativeNo(-1).toString().substring(0, 4);
                this.weekFrom = retailYear + "01";
                this.weekTo = retailYear + ("0" + this.getWeekNoFromRelativeNo(-1)).slice(-2);
                this.dropdownLabel = dateTypeSelected.name + " " + retailYear;
            }
            else {
                this.dropdownLabel = dateTypeSelected.name;
                if (this.getRelativeNoFromWeek(this.weekFrom) == 0)
                    this.weekFrom = this.getWeekFromRelativeNo(-1);
                if (this.getRelativeNoFromWeek(this.weekTo) == 0)
                    this.weekTo = this.getWeekFromRelativeNo(-1);
            }
            this.emitWeeks(this.weekFrom, this.weekTo, isCurrentWeek);
        }
    },
    async mounted() {
        await this.getCalendarData();
        this.dateTypeSelected = this.dateTypes[1];
        this.dateTypes = [...this.dateTypes, ...this.weeksList.map(range => ({ id: range.id, name: "Week: " + range.name }))];
    },
    methods: {
        async getCalendarData() {
            this.calendarData = await calendarSvc.getCalendar();
            const excludeCurrentWeek = this.calendarData.filter(data => data.relativeRetailWeek < 0);
            const weekDataOnly = excludeCurrentWeek.map(calendar => calendar.retailWeekYear);
            const uniqueWeekData = [...new Set(weekDataOnly.flat(1))].reverse();
            this.weeksList = uniqueWeekData.map(week => ({ id: week, name: String(week) }));
        },
        toggleDateTypeSeletion() {
            this.showDateTypeSelection = !this.showDateTypeSelection;
        },
        setDateType(dateType) {
            if (dateType.id.toString().length >= 5) {
                this.weekFrom = dateType.id;
                this.setStartRange({ id: dateType.id, name: dateType.id.toString() });
                this.dateTypeSelected = this.dateTypes[3];
            }
            else
                this.dateTypeSelected = dateType;
            this.toggleDateTypeSeletion();
        },
        setStartRange(selection) {
            let isCurrentWeek = false;
            this.weekFrom = selection.id;
            if (this.dateTypeSelected.id == "CW" || this.getRelativeNoFromWeek(selection.id) == 0) {
                isCurrentWeek = true;
                this.weekTo = selection.id;
            }
            if (this.weekFrom > this.weekTo)
                this.weekTo = this.weekFrom;
            this.emitWeeks(this.weekFrom, this.weekTo, isCurrentWeek);
        },
        setEndRange(selection) {
            this.weekTo = selection.id;
            this.emitWeeks(this.weekFrom, this.weekTo, false);
        },
        emitWeeks(fromWeek, toWeek, isCurrentWeek) {
            this.$emit("weeks", { from: fromWeek, to: toWeek, isCurrentWeek: isCurrentWeek });
        },
        getWeekFromRelativeNo(relativeWeekId) {
            return this.calendarData.find(calendar => calendar.relativeRetailWeek == relativeWeekId).retailWeekYear;
        },
        getWeekNoFromRelativeNo(relativeWeekId) {
            return this.calendarData.find(calendar => calendar.relativeRetailWeek == relativeWeekId).retailWeek;
        },
        getRelativeNoFromWeek(WeekId) {
            return this.calendarData.find(calendar => calendar.retailWeekYear == WeekId).relativeRetailWeek;
        },
        async until(conditionFunction) {
            const poll = resolve => {
                if (conditionFunction())
                    resolve();
                else
                    setTimeout(_ => poll(resolve), 400);
            };
            return new Promise(poll);
        },
    },
};
