import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    class: "mt-5 mx-5",
    style: { "max-width": "1000px" }
};
const _hoisted_2 = { class: "my-5" };
const _hoisted_3 = { class: "my-5" };
const _hoisted_4 = { class: "my-5" };
import { ref, watch } from "vue";
import RecommendedReports from "@/components/homepage/RecommendedReports.vue";
import ReportList from "@/components/homepage/ReportList.vue";
import RecentReports from "@/components/homepage/RecentReports.vue";
import SearchBar from "@/components/homepage/SearchBar.vue";
export default _defineComponent({
    __name: 'HomePage',
    props: {
        newReportCookie: Object
    },
    emits: ["collapseSidebar"],
    setup(__props, { emit }) {
        const props = __props;
        const recentReportsRef = ref(null);
        const reportListRef = ref(null);
        const searchBarRef = ref(null);
        const showSearch = ref(true);
        const handleReportOpened = () => {
            recentReportsRef.value?.getRecentReports();
        };
        const handleSearchStringUpdated = (searchString) => {
            showSearch.value = searchString.trim().length > 0 ? false : true;
            reportListRef.value?.searchFilter(searchString);
        };
        watch(props, (newVal) => {
            updateReportCookie(newVal.newReportCookie);
        });
        function updateReportCookie(newReportCookie) {
            reportListRef.value?.updateCookie(newReportCookie);
        }
        function handleCollapseSidebar() {
            emit("collapseSidebar");
        }
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", null, [
                    _createVNode(RecommendedReports, { onCollapseSidebar: handleCollapseSidebar })
                ]),
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(SearchBar, {
                        ref_key: "searchBarRef",
                        ref: searchBarRef,
                        onSearchStringUpdated: handleSearchStringUpdated
                    }, null, 512)
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _withDirectives(_createVNode(RecentReports, {
                        ref_key: "recentReportsRef",
                        ref: recentReportsRef,
                        onCollapseSidebar: handleCollapseSidebar
                    }, null, 512), [
                        [_vShow, showSearch.value]
                    ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                    _createVNode(ReportList, {
                        ref_key: "reportListRef",
                        ref: reportListRef,
                        onReportOpened: handleReportOpened,
                        onCollapseSidebar: handleCollapseSidebar
                    }, null, 512)
                ])
            ]));
        };
    }
});
